import {
    useFloating,
    useHover,
    useFocus,
    useInteractions,
    FloatingArrow,
    arrow,
  } from "@floating-ui/react"
  import { useRef, useState } from "react"
  
  function Tooltip({ children, text, placement }) {
    const [isOpen, setIsOpen] = useState(false)
  
    const arrowRef = useRef(null)
  
    const { refs, floatingStyles, context } = useFloating({
      middleware: [
        arrow({
          element: arrowRef,
        }),
      ],
      open: isOpen,
      onOpenChange: setIsOpen,
      placement: placement || "bottom",
    })
  
    const hover = useHover(context)
    const focus = useFocus(context)
  
    const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus])
  
    return (
      <>
        <div ref={refs.setReference} {...getReferenceProps()}>
          {children}
        </div>
        {isOpen && text ? (
          <div
            className="z-10 mt-2 max-w-md rounded bg-black px-3 py-2 text-xs font-bold text-white"
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <FloatingArrow ref={arrowRef} context={context} />
            {text}
          </div>
        ) : null}
      </>
    )
  }
  
  export default Tooltip
  