import Step from "./Step";
import Button from "../../Components/Button";
import { Field } from 'formik'
import * as Yup from "yup"
import FormikField from '../../Components/Forms/FormikField'
import NamesPreview from "../../Components/NamesPreview";
import Accordion from "../../Components/Accordion";

const maxMembers = 20;

const shape = {
    'name': Yup.string().required('Required')
}
const schema = Yup.object().shape(shape);

const step =  {
    name: "Mentor Circle",
    title: "Select Your Mentor Circle",
    type: "youth",
    moreInfo: <div>
    <div className="mb-4">"Therefore, since we are surrounded by so great a cloud of witnesses, let us also lay aside every weight, and sin which clings so closely, and let us run with endurance the race that is set before us, looking to Jesus, the founder and perfecter of our faith."<br/>Hebrews 12:1-2</div>
    <div>
        <div>Helpful Links:</div>
        <ul>
            <li><a target="_blank" className="underline text-black/50" href="https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Personal%20Journey%20-%20How%20to%20Choose%20a%20Mentor%20Circle.pdf">How to Choose a Mentor Circle</a></li>
        </ul>
    </div>
</div>,
    instructions: [
        "Your Mentor Circle is a group of adults you are comfortable with and trust to support you in your goals.",
        "Select 2 to 10 people that will intentionally help you accomplish your goals as members of your Mentor Circle."
    ],
}

function MentorCircle() {
    const getPreviewData = (data) => {return data.members}
    const getIsComplete = (data) => {return !!data.members.length}
    const onSubmit = (values, actions, onSubmit) => {
        onSubmit(
            {members: [values.name]},
            false,
            false
        )
        actions.resetForm();
    }
    return <Step      
                id="members"           
                step={step}
                Preview={NamesPreview} 
                getIsComplete={getIsComplete}
                getPreviewData={getPreviewData}
                keys={['members']}
                schema={schema}
                onSubmit ={onSubmit}
                image="/circle.png"
                theme="circle"

            >
                {(values, data, refreshData)=>(
                    <>
                    <FormikField primary={true} name="name" instructions={step.instructions} title={step.title}>
                    <div className="mb-6">
                            <Accordion title="More Info" theme="minimalist">
                                {step.moreInfo}
                            </Accordion>
                        </div>
                        <div className="mb-4 text-sm">Name your 2 to 10 Mentor Circle Members below</div>
                        <NamesPreview data={data.members} theme="circle" deletable={true} onDelete={refreshData}/>
                         {data.members.length < maxMembers ?  <div class="flex gap-4 items-center mb-2 mt-4">
                            <Field key={data.members.length + 1} name="name" placeholder="Name" className="p-2 border rounded-md w-full max-w-xs"/>
                        </div> : null}
                    </FormikField> 
                    {data.members.length < maxMembers ?  <Button bgColor="bg-circle hover:bg-circle/90" type="submit">Add Mentor Circle Member</Button> : null }
                    </>
                )}
    </Step>
}

export default MentorCircle;