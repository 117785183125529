import Button from "../Components/Button";

function Join({onJoin, plan}) {
    return <div>
        <h1 className="font-bold text-2xl text-center mb-6">Join</h1>
        <p className="mb-4">Would you like to join {plan.name}'s Plan as a mentor?</p>
        <Button onClick={onJoin}>Join</Button>
    </div>
  }
  
  export default Join
  