import { useAtom } from "jotai";
import { twJoin } from "tailwind-merge"
import { deleteConfirmationAtom, userAtom } from "../state";
import api from "../services/api";

function NamesPreview({data, theme, deletable = false, onDelete}) {
    const [user, setUser] = useAtom(userAtom)
    const [deleteConfirmation, setDeleteConfirmation] = useAtom(deleteConfirmationAtom);

    let border = "border-brand";
    if (theme == "circle"){
        border = "border-circle";
    }

    const onDeleteClick = (id) => {
        setDeleteConfirmation(()=>()=>{
            api.deletePlanContent(id).then((response)=>{
                onDelete();
            })
        })
    }

    const dataToShow = !theme ? data.slice(0, 5) : data;

    return <div><ul>
        {
            dataToShow.map((item, index) => {
                return (
                    <li key={index} className="flex gap-4 items-center mb-4">
                        <div className={twJoin("flex border-b gap-2 font-bold border px-4 py-2 rounded-md text-black w-80", border)}>
                            <span className="text-ellipsis overflow-hidden">{item.value}</span>
                        </div>
                        {item.user_id == user.id && deletable ? <i onClick={()=>onDeleteClick(item.id)} className="fa-solid fa-square-minus text-neutral-400 mt-0.5"></i> : null}
                    </li>
                )
            }
        )}  
    </ul>
    {data.length > 5 && !theme ? <div className="font-bold">+{data.length - 5} more</div> : null}
    </div>
}
  
  export default NamesPreview