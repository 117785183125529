import axios from "axios"
let apiClient = axios.create({
  baseURL: import.meta.env.VITE_API_BASE,
  withCredentials: true,
  withXSRFToken: true,
})

export default {
  getPlan(id) {
    return apiClient.get("/plan/" + id)
  },
  getPlanPdf(token) {
    return apiClient.get("/plan/" + token + "/pdf", {
      responseType: 'blob'
    })
  },
  deletePlan(token) {
    return apiClient.delete("/plan/" + token)
  },
  getPlans() {
    return apiClient.get("/plan")
  },
  getPlanContent(token, keys){
    return apiClient.get("/plan-content?plan_token=" + token + "&" + keys.map(key => "keys[]=" + key).join("&"))
  },
  createPlanContent(data){
    return apiClient.post("/plan-content", data)
  },
  deletePlanContent(id){
    return apiClient.delete("/plan-content/" + id)
  },
  createPlan(data){
    return apiClient.post("/plan", data)
  },
  updatePlan(token, data){
    return apiClient.put("/plan/" + token, data)
  },
  createPlanUser(data){
    return apiClient.post("/plan-user", data)
  },
  register(data) {
    return apiClient.post("/auth/register", data)
  },
  updateUser(id, data) {
    return apiClient.put("/user/" + id, data)
  },
  forgotPassword(data) {
    return apiClient.post("/auth/forgot-password", data)
  },
  resetPassword(data) {
    return apiClient.post("/auth/reset-password", data)
  },
  login(data) {
    return apiClient.post("/auth/login", data)
  },
  logout() {
    return apiClient.post("/auth/logout")
  },
  getUser() {
    return apiClient.get("/user")
  },
  createMedia(file, planToken) {
    const formData = new FormData()
    formData.append("file", file, 'audio.wav')
    formData.append("plan_token", planToken)
    return apiClient.post("/media", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  },
  getMedia(id) {
    return apiClient.get("/media/" + id)
  },
  deleteMedia(id) {
    return apiClient.delete("/media/" + id)
  },
  forgotPassword(data) {
    return apiClient.post("/auth/forgot-password", data)
  },
  resetPassword(data) {
    return apiClient.post("/auth/reset-password", data)
  },
}