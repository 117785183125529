import { Field, Form, Formik } from "formik";
import FormikField from "../Components/Forms/FormikField";
import Button from "../Components/Button";
import { useAtom } from "jotai";
import { userAtom } from "../state"
import api from "../services/api";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
    const [user, setUser] = useAtom(userAtom)
    const navigate = useNavigate();
    const handleSubmit = (values, {setErrors})  => {
        api.login(values).then(response => {
            setUser(response.data);
        }).catch((error)=>{
          setErrors(error.response.data.errors);
       });
      }

    return  <div className="min-h-screen flex flex-col justify-between">
        
        <div className="p-12 max-w-5xl w-full mx-auto">
            <img className="w-[300px] mb-16" src="/logo.png"/>
            <div className="flex flex-col gap-8 md:gap-16 md:flex-row">
            
            <div className="flex-1">
                <div className=" mb-12">
                    <h1 className="font-bold text-xl text-black mb-8">Login</h1>
                </div>
                <Formik initialValues={{email: "", password: ""}} onSubmit={handleSubmit}>
                    <Form>                       
                        <FormikField name="email" title="Email"  instructions={[]}>
                            <Field name="email" className="w-full p-4 border-0 rounded-md mb-4 bg-neutral-100"/>
                        </FormikField>
                        <FormikField name="password" title="Password" instructions={[]}>
                            <Field type="password" name="password" className="w-full p-4 border-0 rounded-md mb-4 bg-neutral-100"/>
                        </FormikField>
                        <Button type="submit">Login</Button>
                    </Form>
                </Formik>
                <p class="text-brand-secondary text-base font-bold mt-10"><Link to="/forgot-password" className="underline text-brand dark:text-white">Forgot Password?</Link></p>

            </div>
            <div className="flex-1 text-center">
                <div className="border border-neutral-500 rounded-xl px-4 py-12">
                    <h1 className="font-bold text-xl text-black mb-8">Register</h1>
                    <div className="mb-6 font-bold text-lg">Need an Encircled account?</div>
                    <Button onClick={()=>navigate('/register')}>Register</Button>
                </div>
                
            </div>
        </div>
        </div>

    </div>
}

export default Login;