import Step from "./Step";
import ListPreview from "../../Components/ListPreview";
import Button from "../../Components/Button";
import * as Yup from "yup"
import FormikField from '../../Components/Forms/FormikField'
import { Field } from 'formik'
import { twJoin } from "tailwind-merge";
import Accordion from "../../Components/Accordion";

const step = {
    name: "Identity",
    title: "Select 2 or 3 Identity Statements",
    instructions: [
        "The Identity Statements below illustrate some Bible verses that tell us how God truly sees us.",
        "Please select 2 or 3 that you want your Mentor Circle to know resonate with you the most."
    ],
    type: "youth",
    moreInfo: <div>
            <div className="mb-4">"Yet to all who did receive Him, to those who believed in His Name, He gave the right to become children of God."<br/>John 1:12</div>
            <div>
                <div>Helpful Links:</div>
                <ul>
                    <li><a target="_blank" className="underline text-black/50" href="https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Personal%20Journey%20-%20Discovering%20Your%20Identity%20-%2014%20Biblical%20Identity%20Statements.pdf">14 Biblical Identity Statements</a></li>
                </ul>
            </div>
    </div>,
    options: [
        {
            "value": "unique",
            "label": "You are Unique",
            ref: "Ps 139:13-14",
            description: "You made all the delicate, inner parts of my body and knit me together in my mother's womb. Thank you for making me so wonderfully complex! Your workmanship is marvelous – and how well I know it. Psalm 139:13-14 (NLT)"
        },
        {
            "value": "loved",
            "label": "You are Loved",
            ref: "Jer 31:3",
            description: "I have loved you with an everlasting love; therefore I have continued my faithfulness to you. Jeremiah 31:3 (ESV)"
        },
        {
            "value": "beautiful",
            "label": "You are Beautiful",
            ref: "Ps 45:11",
            description: "So the King will greatly desire your beauty; Because He is your Lord, worship Him. Psalm 45:11 (NKJV)"
        },
        {
            value: "special",
            label: "You are Special",
            ref: "Eph 2:10",
            description: "For we are his workmanship, created in Christ Jesus for good works, which God prepared beforehand, that we should walk in them. Ephesians 2:10 (NIV)"
        },
        {
            "value": "purpose",
            "label": "You were Created for a Purpose",
            ref: "Jer 29:11",
            description: "I know what I’m doing. I have it all planned out—plans to take care of you, not abandon you, plans to give you the future you hope for. Jeremiah 29:11 (The Message)"
        },
        {
            "value": "strong",
            "label": "You are Strong",
            ref: "Ps 18:35",
            description: "You have given me your shield of victory. Your right hand supports me; your help has made me great. Psalm 18:35 (NLT)"
        },
        {
            "value": "important",
            "label": "You are Important",
            ref: "1 Pet 2:9",
            description: "For you are a chosen people. You are royal priests, a holy nation, God’s very own possession. As a result, you can show others the goodness of God, for he called you out of the darkness into his wonderful light. 1 Peter 2:9 (NLT)"
        },
        {
            "value": "forgiven",
            "label": "You are Forgiven",
            ref: "Ps 103:12",
            description: "For as high as the heavens are above the earth, so great is his love for those who fear him; as far as the east is from the west, so far has he removed our sins from us. Psalm 103:12 (NIV)"
        },
        {
            "value": "new_creation",
            "label": "You are a New Creation",
            "ref": "2 Cor 5:17",
            description: "This means that anyone who belongs to Christ has become a new person. The old life is gone; a new life has begun! 2 Corinthians 5:17 (NLT)"
        },
        {
            value: "protected",
            label: "You are Protected",
            "ref": "Ps 121:6-8",
            description: "I will lift up my eyes to the mountains. Where will my help come from? My help comes from the Lord, Who made heaven and earth. He will not let your feet go out from under you. He who watches over you and will not sleep. Listen, He who watches over Israel will not close his eyes or sleep. Psalm 121:6-8 (NLT)"
        },
        {
            value: "empowered",
            label: "You are Empowered",
            "ref": "Phil 4:13",
            description: "I can do all things because Christ gives me strength. Philippians 4:13 (NLT)"
        },
        {
            value: "chosen",
            label: "You are Chosen",
            ref: "John 15:16",
            description: "You did not choose Me, but I chose you and appointed you that you should go and bear fruit and that your fruit should remain, that whatever you ask the Father in My name He may give you. John 15:16 (NLT)"
        },
        {
            value: "family",
            label: "You are Family",
            ref: "Eph 2:19",
            description: "From now on you are not strangers and people who are not citizens. You are citizens together with those who belong to God. You belong in God’s family. Ephesians 2:19 (NLT)"
        },
        {
            value: "mine",
            label: "You are Mine", 
            "ref": "Isa 43:1",
            description: "But now the Lord Who made you, O Jacob, and He Who made you, O Israel, says, “Do not be afraid. For I have bought you and made you free. I have called you by name. You are Mine! Isaiah 43:1 (NLT)"
        }
    ]
  }

const shape = {
    identities: Yup.array().of(Yup.string().required('Required')).min(2, 'Please select 2-3 Identity Affirmations.').max(3, 'Please select 2-3 Identity Affirmations.')
}
const schema = Yup.object().shape(shape);

function Identity() {
    const getIsComplete = (data) => !!data.identities.length
    const getPreviewData = (data) => data.identities.map((item) => {
        const option = step.options.find(option => option.value == item)
        return {
            description: option.description,
            value: option.label
          }
    })
    return <Step 
                step={step}
                Preview={ListPreview} 
                getIsComplete={getIsComplete}
                getPreviewData={getPreviewData}
                keys={Object.keys(shape)}
                schema={schema}
                image="/identity.svg"
            >
                {()=>(
                    <>
                        <FormikField primary={true} name="identities" instructions={step.instructions} title={step.title}>
                        <div className="mb-4">
                            <Accordion title="More Info" theme="minimalist">
                                {step.moreInfo}
                            </Accordion>
                        </div>
                        <div className="mb-4 text-sm">Please select 2 to 3 options below</div>
                            <div className="grid md:grid-cols-2 gap-x-8">
                                {step.options.map((option, index) => {
                                    return <div key={index} className={twJoin("py-3 border-b border-neutral-300", index == step.options.length -1 ? "border-b-0": "",index == step.options.length -2 ? "md:border-b-0": "")}>
                                        <label className="flex gap-3">
                                            <Field type="checkbox" className="h-7 w-7 rounded border-gray-300 text-brand focus:ring-brand" name="identities" value={option.value}/>
                                            <div className="flex-1">
                                                <Accordion title={option.label} subtitle={option.ref} classes="py-2 px-4" theme="minimalist">
                                                    {option.description}
                                                </Accordion>
                                            </div>
                                        </label>
                                    </div>
                                })}
                            </div>
                        </FormikField>
                        <Button bgColor="bg-brand hover:bg-brand/90" type="submit">Save</Button>
                    </>
                )}
    </Step>
}

export default Identity;