import { Field, Form, Formik } from "formik"
import api from "../services/api"
import FormikField from "../Components/Forms/FormikField";
import * as Yup from "yup"
import Button from "../Components/Button";


const schema = Yup.object().shape({
    name: Yup.string().required('Required'),
});

function AddPlan({onAdd}) {
    const onSubmit = (values) => {
        api.createPlan(values).then((response) => {
            onAdd(response.data)
        })
    }

    return <div>
        <h1 className="font-bold text-2xl text-center mb-6">Add a Plan</h1>
        <Formik
                    onSubmit={onSubmit}
                    initialValues={{
                        name: ''
                    }}
                    validationSchema={schema}
                    validateOnChange={false}
                    validateOnBlur={false}
                >   
                    {({values}) =>
                    <Form>
                        <FormikField name="name" instructions={[]} title="Youth Name">
                            <Field name="name" placeholder="Name" className="w-full p-2 border rounded-md mb-4"/>
                        </FormikField>
                        <Button type="submit">Add</Button>
                    </Form>
                    }
        </Formik>
    </div>
  }
  
  export default AddPlan
  