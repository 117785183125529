
import { useAtom } from "jotai";

import { deleteConfirmationAtom } from "../state";
import Modal from "./Modal";
import Button from "./Button";

const DeleteConfirmation = () =>{

    const [deleteConfirmation, setDeleteConfirmation] = useAtom(deleteConfirmationAtom);

    return <Modal width="md:max-w-xl" isOpen={deleteConfirmation !== null} setIsOpen={()=>setDeleteConfirmation(null)}>
        <div className="text-center">
            <h1 className="font-bold text-brand-secondary text-xl mb-4">Are you sure you want to delete this?</h1>
            <div>
                <button onClick={()=>{
                    deleteConfirmation();
                    setDeleteConfirmation(null);  
                }}><Button bgColor="bg-brand mb-4">Delete</Button></button>
            <div>
            <button onClick={()=>setDeleteConfirmation(null)}>Cancel</button>
            </div>
            </div>
        </div>
    </Modal>
}

export default DeleteConfirmation