import Step from "./Step";
import { useState } from "react";
import Button from "../../Components/Button";
import { Field } from 'formik'
import * as Yup from "yup"
import FormikField from '../../Components/Forms/FormikField'
import BlessingsPreview from "../../Components/BlessingsPreview";
import Accordion from "../../Components/Accordion";
import Recorder from "../../Components/Recorder";
import Modal from "../../Components/Modal";
import { useAtom } from "jotai";
import { planTokenAtom, roleAtom, userAtom } from "../../state";
import api from "../../services/api";
import GoalBlock from "../../Components/GoalBlock";

const shape = {
    'blessing': Yup.string().required('Required')
}

const schema = Yup.object().shape(shape);

const step = {
    name: "Blessings",
    title: "Share a blessing",
    type: "mentors",
    instructions: [
        "Bless your youth on their journey ahead.",
        "Submit an audio blessing or a written blessing below"
    ],
    moreInfo: <div>
<div className="mb-4">The time of blessing is a chance to encourage the youth by affirming their value and speaking life into their journey. Lift them up with meaningful words that inspire and support their future.</div>
<div>
    <div>Helpful Links:</div>
    <ul>
        <li><a target="_blank" className="underline text-black/50" href="https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Personal%20Journey%20-%20How%20to%20Give%20and%20Receive%20Blessings.pdf">How to Give and Receive Blessings</a></li>
        
    </ul>
</div>
</div>
  };


function Blessings() {
    const [user] = useAtom(userAtom)
    const [role] = useAtom(roleAtom);
    const [activeType, setActiveType] = useState(null)
    const [isAudioOpen, setIsAudioOpen] = useState(false)
    const [recorderKey, setRecorderKey] = useState(0)
    const [planToken] = useAtom(planTokenAtom);
    const getPreviewData = (data) => {return data}
    const getIsComplete = (data) => {
        if (role =='youth'){
            return data.audio_blessings.length > 0 || data.text_blessings.length > 0;
        }
        return data.audio_blessings.filter((item) => item.user_id == user.id).length > 0 || 
        data.text_blessings.filter((item) => item.user_id == user.id).length > 0
    }

    const onAudioComplete = (blob, refreshData) => {
        api.createMedia(blob, planToken).then((response)=>{
            api.createPlanContent({
                reset: 0,
                content: [
                    {
                        key: 'audio_blessings',
                        value: response.data.id,
                        plan_token: planToken
                    }
                ]
            }, planToken).then((response)=>{
                refreshData()
            })
        })
        setIsAudioOpen(false)
    }
    const onSubmit = (values, actions, onSubmitIn) => {
        onSubmitIn(
            {[activeType == 'audio' ? 'audio_blessings' : 'text_blessings']: [values.blessing]},
            false,
            false
        )
        actions.resetForm();
        setActiveType(null)
    }
    return <Step 
                step={step}
                Preview={BlessingsPreview} 
                getIsComplete={getIsComplete}
                getPreviewData={getPreviewData}
                keys={['audio_blessings', 'text_blessings','visions', 'other_title', 'faith_vision', 'family_vision', 'sports_vision', 'school_vision', 'arts_music_vision', 'travel_vision', 'hobbies_vision', 'service_vision', 'other_vision', 'relationships_vision', 'work_vision']}
                onSubmit={onSubmit}
                schema={schema}
                image="/blessings.svg"
                theme="blessings"
            >
                {(values, data, refreshData)=>(
              <>
                    <FormikField primary={true} name="blessing" instructions={step.instructions} title={step.title}>
                    <GoalBlock data={data}/>
                    <div className="mb-6">
                            <Accordion title="More Info" theme="minimalist">
                                {step.moreInfo}
                            </Accordion>
                        </div>
                        <div className="mb-4 text-sm">
                            Share an Audio or Written Blessing below
                        </div>
                        <div className="mb-4">
                            <BlessingsPreview deletable={true} data={data} editable={true} theme="blessings" onDelete={refreshData}/>
                        </div>
                        {activeType==null ? <div className="flex flex-col md:flex-row gap-4 w-full">
                            <button className="font-bold flex flex-1 items-center" onClick={()=>setIsAudioOpen(true)}>
                                <i className="fas fa-plus-square text-blessings mr-2 text-3xl"></i>
                                <span>Record Audio Blessing</span>
                            </button>
                            <button className="font-bold flex flex-1 items-center" onClick={()=>setActiveType('text')}>
                                <i className="fas fa-plus-square text-blessings text-3xl mr-2"></i>
                                <span>Add Text Blessing</span>
                            </button>
                        </div> : null}
                        {activeType == 'text' ? <><div class="flex gap-4 items-center mb-2">
                            <Field as="textarea" key={"text"+data.text_blessings.length + 1} name="blessing" placeholder="I commit to..." className="p-2 border rounded-md w-full h-32"/>
                        </div>
                        <Button bgColor="bg-blessings hover:bg-blessings/90"  type="submit">Add blessing</Button>
                        </> : null} 
                        
                    </FormikField> 
                    <Modal isOpen={isAudioOpen} setIsOpen={setIsAudioOpen} width="md:max-w-lg">
                        <Recorder key={recorderKey} onReset={()=>{
                            setRecorderKey(recorderKey + 1)
                        }} onComplete={(blob)=>onAudioComplete(blob,refreshData)}/>
                    </Modal>
                    </>
                )}
    </Step>
}

export default Blessings;