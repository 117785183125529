import { Field, Form, Formik } from "formik"
import api from "../services/api"
import FormikField from "../Components/Forms/FormikField";
import * as Yup from "yup"
import Button from "../Components/Button";
import { deleteConfirmationAtom } from "../state";
import { useAtom } from "jotai";


const schema = Yup.object().shape({
    name: Yup.string().required('Required'),
});

function EditPlan({onEdit, onDelete, plan}) {
    const [deleteConfirmation, setDeleteConfirmation] = useAtom(deleteConfirmationAtom);

    const onSubmit = (values) => {
       api.updatePlan(plan.token, values).then(response => {
           onEdit();
       });
    }

    const onDeleteClick = (e) => {
        setDeleteConfirmation(()=>()=>{
            api.deletePlan(plan.token).then(response => {
                onDelete();
            });
        })
    }

    return <div>
        <h1 className="font-bold text-2xl text-center mb-6">Edit Plan</h1>
        <Formik
                    onSubmit={onSubmit}
                    initialValues={{
                        name: plan.name
                    }}
                    validationSchema={schema}
                    validateOnChange={false}
                    validateOnBlur={false}
                >   
                    {({values}) =>
                    <Form>
                        <FormikField name="name" instructions={[]} title="Youth Name">
                            <Field name="name" placeholder="Name" className="w-full p-2 border rounded-md mb-4"/>
                        </FormikField>
                        <div className="flex justify-between items-center">
                        <Button type="submit">Update</Button>
                        <button className="underline text-xs" onClick={onDeleteClick}>Delete Plan</button>

                        </div>

                    </Form>
                    }
        </Formik>
    </div>
  }
  
  export default EditPlan
  