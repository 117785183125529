import Step from "./Step";
import ListPreview from "../../Components/ListPreview";
import Button from "../../Components/Button";
import * as Yup from "yup"
import FormikField from '../../Components/Forms/FormikField'
import { Field } from 'formik'
import Accordion from "../../Components/Accordion";
import { twJoin } from 'tailwind-merge'

const shape = {
    gifts: Yup.array().of(Yup.string().required('Required'))
}

const step = {
    name: "Gifts",
    title: "Select 3 to 5 Gifts",
    type: "youth",
    moreInfo: <div>
    <div className="mb-4">“God has given each of us unique gifts to be used for His glory. When we recognize and use our gifts, we fulfill His purpose for our lives.” 1 Peter 4:10</div>
    <div>
        <div>Helpful Links:</div>
        <ul>
            <li><a target="_blank" className="underline text-black/50" href="http://encircled.pro.viasurvey.org">VIA Gifts Inventory</a></li>
        </ul>
    </div>
</div>,
    instructions: [
      "The Gifts below represent some of the unique abilities God has given you.",
      "Please select 3 to 5 that you see in yourself and that you want to share with your Mentor Circle."
    ],
    optionGroups: [
      { 
        label: "Wisdom",
        options: [
          {
              value: "creativity",
              label: "Creativity",
              description: "\"I am creative, conceptualizing something useful, coming up with ideas that result in something worthwhile.\""
          },
          {
            value: "curiosity",
            label: "Curiosity",
            description: "\"I seek out situations where I gain new experiences without getting in my own or other people’s way.\""
         },
         {
            value: "Judgment",
            label: "Judgment",
            description: "\"I weigh all aspects objectively in making decisions, including arguments that are in conflict with my convictions.\""
         },
         {
            value: "love_of_learning",
            label: "Love of Learning",
            description: "\"I am motivated to acquire new levels of knowledge, or deepen my existing knowledge or skills in a significant way.\""
         },
         {
            value: "perspective",
            label: "Perspective",
            description: "\"I give advice to others by considering different (and relevant) perspectives and using my own experiences and knowledge to clarify the big picture.\""
         }
        ],
      },
      {
        label: "Courage",
        options: [
          {
              value: "bravery",
              label: "Bravery",
              description: "\"I act on my convictions, and I face threats, challenges, difficulties, and pains, despite my doubts and fears.\""
          },
          {
            value: "honesty",
            label: "Honesty",
            description: "\"I am honest to myself and to others, I try to present myself and my reactions accurately to each person, and I take responsibility for my actions.\""
         },
         {
            value: "perseverance",
            label: "Perseverance",
            description: "\"I persist toward my goals despite obstacles, discouragements, or disappointments.\""
         },
         {
            value: "zest",
            label: "Zest",
            description: "\"I feel vital and full of energy, I approach life feeling activated and enthusiastic.\""
         }
        ]
      },
      {
        label: "Humanity",
        options: [
          {
              value: "kindness",
              label: "Kindness",
              description: "\"I am helpful and empathic and regularly do nice favors for others without expecting anything in return.\""
          },
          {
            value: "love",
            label: "Love",
            description: "\"I experience close, loving relationships that are characterized by giving and receiving love, warmth, and caring.\""
         },
         {
            value: "social_intelligence",
            label: "Social Intelligence",
            description: "\"I am aware of and understand my feelings and thoughts, as well as the feelings of those around me.\""
         }
        ]
      },
      {
        label: "Justice",
        options: [
          {
              value: "fairness",
              label: "Fairness",
              description: "\"I treat everyone equally and fairly, and give everyone the same chance applying the same rules to everyone.\""
          },
          {
            value: "leadership",
            label: "Leadership",
            description: "\"I take charge and guide groups to meaningful goals, and ensure good relations among group members.\""
         },
         {
            value: "teamwork",
            label: "Teamwork",
            description: "\"I am a helpful and contributing group and team member, and feel responsible for helping the team reach its goals.\""
         }
        ]
      },
      {
        label: "Temperance",
        options: [
          {
              value: "forgiveness",
              label: "Forgiveness",
              description: "\"I forgive others when they upset me and/or when they behave badly towards me, and I use that information in my future relations with them.\""
          },
          {
            value: "humility",
            label: "Humility",
            description: "\"I see my strengths and talents but I am humble, not seeking to be the center of attention or to receive recognition.\""
         },
         {
            value: "prudence",
            label: "Prudence",
            description: "\"I act carefully and cautiously, looking to avoid unnecessary risks and planning with the future in mind.”"
         },
        {
            value: "self_regulation",
            label: "Self-Regulation",
            description: "\"I manage my feelings and actions and am disciplined and self-controlled.\""
        }
        ]
      },
      {
        label: "Transcendence",
        options: [
          {
              value: "appreciation_of_beauty_&_excellence",
              label: "Appreciation of Beauty & Excellence",
              description: "\"I recognize, emotionally experience, and appreciate the beauty around me and the skill of others.\""
          },
          {
            value: "gratitude",
            label: "Gratitude",
            description: "\"I am grateful for many things and I express that thankfulness to others.\""
         },
         {
            value: "hope",
            label: "Hope",
            description: "\"I am realistic and also full of optimism about the future, believing in my actions and feeling confident things will turn out well.\""
         },
         {
            value: "humor",
            label: "Humor",
            description: "\"I approach life playfully, making others laugh, and finding humor in difficult and stressful times.\""
         },
         {
            value: "spirituality",
            label: "Spirituality",
            description: "\"I feel spiritual and believe in a sense of purpose or meaning in my life; and I see my place in the grand scheme of the universe and find meaning in everyday life.\""
            }
        ]
      }
    ]
  };

const schema = Yup.object().shape(shape);

function Gifts() {
    const getIsComplete = (data) => !!data.gifts.length
    const getPreviewData = (data) => data.gifts.map((item) => {
        const option = step.optionGroups.flatMap(group => group.options).find(option => option.value == item)
        return {
          description: option.description,
          value: option.label
      }

    })

    return <Step 
                step={step}
                Preview={ListPreview} 
                getIsComplete={getIsComplete}
                getPreviewData={getPreviewData}
                keys={Object.keys(shape)}
                schema={schema}
                image="/gifts.svg"
                theme="gifts"
            >
                {()=>(
                    <>
                        <FormikField primary={true} name="gifts" instructions={step.instructions} title={step.title}>
                        <div className="text-sm -mt-2 mb-4">NOTE: We strongly encourage you to take the <a className="underline text-gifts" target="_blank" href='http://encircled.pro.viasurvey.org/'>VIA Strengths Survey</a> before completing this section.</div>
                        <div className="mb-6">
                            <Accordion title="More Info" theme="minimalist">
                                {step.moreInfo}
                            </Accordion>
                        </div>  
                        <div className="mb-4 text-sm">Please select 3 to 5 options below</div>

                            <CheckboxesField name="gifts" optionGroups={step.optionGroups}/>
                        </FormikField>
                        <Button bgColor="bg-gifts hover:bg-gifts/90" type="submit">Save</Button>
                    </>
                )}
    </Step>
}

const CheckboxesField = ({name, optionGroups}) => {    
    return< div className="grid md:grid-cols-2 gap-8">
        {optionGroups.map((group, groupIndex) => {
            return <div  key={groupIndex}>
            {group.label ? <div className="font-bold text-xl uppercase text-gifts border-b border-neutral-300 pb-2 mb-2">{group.label}</div> : null}
            {group.options.map((option, index) => {
                return <div key={index} className={twJoin("py-3 border-b border-neutral-300", index == group.options.length -1 ? "border-b-0": "",index == group.options.length -2 ? "md:border-b-0": "")}>
                    <label className="flex gap-3">
                        <Field type="checkbox" className="h-7 w-7 rounded border-gray-300 text-gifts focus:ring-gifts" name={name} value={option.value}/>
                        <div className="flex-1">
                            <Accordion title={option.label} classes="py-2 px-4" theme="minimal">
                               {option.description}
                            </Accordion>
                        </div>
                    </label>
                </div>
            })}
            </div>
        })}
    </div>
  }


export default Gifts;