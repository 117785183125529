import Label from '../../Components/Forms/Label'
import Instructions from '../../Components/Forms/Instructions'
import {ErrorMessage} from 'formik'

const FormikField = ({name, title, instructions, children, primary = false}) => {
    return <div className="mb-4">
        <Label primary={primary} name={name}>{title}</Label>
        <Instructions>{instructions.map((instruction)=>{
            return <p className="mt-2">{instruction}</p>
        })}</Instructions>
        {children}
         <ErrorMessage name={name} render={msg => <div className="text-sm mb-4 bg-red-50 w-full p-4 rounded-lg">{msg}</div>}/>
    </div>
}

export default FormikField;