import { twJoin } from "tailwind-merge"

function ListTextPreview({data,editable, theme}) {
    let textColor = "text-brand";
    if (theme == "encouragement"){
        textColor = "text-encouragement";
    }
    if (theme == "commitments"){
        textColor = "text-commitments";
    }
    return <ul>
        {
            data.map((item, index) => {
                return (
                    <li key={index} className="flex border-b border-neutral-200 gap-2 mb-2 pb-2">
                        <span className={textColor}>{index + 1}) </span>
                        <span className={twJoin("text-sm mt-0.5", editable ? "text-black" : "text-black")}>{item.value} - {item.user_name}</span>
                    </li>
                )
            }
        )}  
    </ul>
}
  
  export default ListTextPreview