import { Field, Form, Formik } from "formik"
import api from "../services/api"
import FormikField from "../Components/Forms/FormikField";
import * as Yup from "yup"
import Button from "../Components/Button";
import { userAtom } from "../state";
import { useAtom } from "jotai";


const schema = Yup.object().shape({
    name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required')
});

function EditAccount({onAccountEdited}) {
    const [user, setUser] = useAtom(userAtom)
   
    const onSubmit = (values, {setErrors}) => {
       api.updateUser(user.id, values).then((response) => {
           setUser(response.data)
           onAccountEdited();
       }).catch((error)=>{
        setErrors(error.response.data.errors);
      });
    }


    return <div>
        <h1 className="font-bold text-2xl text-center mb-6">Edit Account</h1>
        <Formik
                    onSubmit={onSubmit}
                    initialValues={{
                        name: user.name,
                        email: user.email
                    }}
                    validationSchema={schema}
                    validateOnChange={false}
                    validateOnBlur={false}
                >   
                    {({values}) =>
                    <Form>
                        <FormikField name="name" title="Your Name"  instructions={[]}>
                            <Field name="name" className="w-full p-4 border-0 rounded-md mb-4 bg-neutral-100"/>
                        </FormikField>                        
                        <FormikField name="email" title="Email"  instructions={[]}>
                            <Field name="email" className="w-full p-4 border-0 rounded-md mb-4 bg-neutral-100"/>
                        </FormikField>
                        <FormikField name="password" title="Password" instructions={[]}>
                            <Field type="password" name="password" className="w-full p-4 border-0 rounded-md mb-4 bg-neutral-100"/>
                        </FormikField>
                        <Button type="submit">Update</Button>
                    </Form>
                    }
        </Formik>
    </div>
  }
  
  export default EditAccount
  