import { Field, Form, Formik } from "formik";
import FormikField from "../Components/Forms/FormikField";
import Button from "../Components/Button";
import { useAtom } from "jotai";
import { redirectAtom, userAtom } from "../state"
import api from "../services/api";
import { useNavigate } from "react-router-dom";

const Register = () => {
    const [user, setUser] = useAtom(userAtom)
    const [redirect, setRedirect] = useAtom(redirectAtom)
    const navigate = useNavigate();

    const handleSubmit = (values, {setErrors})  => {
        api.register(values).then(response => {
            setUser(response.data);
        }).catch((error)=>{
          setErrors(error.response.data.errors);
       });
      }
    return <div className="min-h-screen flex flex-col justify-between">
        <div className="p-12 max-w-5xl mx-auto">
            <img className="w-[300px] mb-16" src="/logo.png"/>
            <div className="flex flex-col gap-8 md:gap-16 md:flex-row">
            
            <div className="flex-1">
                <div className=" mb-12">
                    {redirect ? <div className="bg-neutral-50 p-6 rounded mb-8 flex gap-4 items-center">
                        <i className="fas fa-info-circle"></i>
                        <div>To view the youth's mentor circle please create an account or login</div>
                        </div> : null}
                    <h1 className="font-bold text-xl text-black mb-8">Sign Up</h1>
                    <div>Create your Encircled account to manage your Mentor Circle, access resources and much more.</div>
                </div>
                <Formik initialValues={{name: "", email: "", password: ""}} onSubmit={handleSubmit}>
                    <Form>
                        <FormikField name="name" title="Your Name"  instructions={[]}>
                            <Field name="name" className="w-full p-4 border-0 rounded-md mb-4 bg-neutral-100"/>
                        </FormikField>                        
                        <FormikField name="email" title="Email"  instructions={[]}>
                            <Field name="email" className="w-full p-4 border-0 rounded-md mb-4 bg-neutral-100"/>
                        </FormikField>
                        <FormikField name="password" title="Password" instructions={[]}>
                            <Field type="password" name="password" className="w-full p-4 border-0 rounded-md mb-4 bg-neutral-100"/>
                        </FormikField>
                        <Button type="submit">Create Account</Button>
                    </Form>
                </Formik>
            </div>
            <div className="flex-1 text-center">
                <div className="border border-neutral-500 rounded-xl px-4 py-12">
                    <h1 className="font-bold text-xl text-black mb-8">Login</h1>
                    <div className="mb-6 font-bold text-lg">Already have an Encircled account?</div>
                    <Button onClick={()=>navigate('/login')}>Login</Button>
                </div>             
            </div>
        </div>
    </div>    
</div>
}

export default Register;