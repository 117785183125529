import { channelAtom, deleteConfirmationAtom, loadingAtom, roleAtom, userAtom } from '../state/';
import { useAtom } from "jotai";
import { twJoin } from "tailwind-merge";
import Identity from "../Partials/Step/Identity";
import Gifts from '../Partials/Step/Gifts';
import Vision from '../Partials/Step/Vision';
import Introduction from '../Partials/Step/Introduction';
import MentorCircle from '../Partials/Step/MentorCircle';
import Encouragement from '../Partials/Step/Encouragement';
import Commitments from '../Partials/Step/Commitments';
import Blessings from '../Partials/Step/Blessings';
import { Link, useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from 'react';
import api from '../services/api';
import { planTokenAtom } from '../state';
import Modal from '../Components/Modal';
import AddPlan from '../Partials/AddPlan';
import EditPlan from '../Partials/EditPlan';
import Invite from '../Partials/Invite';
import Join from '../Partials/Join';
import EditAccount from '../Partials/EditAccount';
import Tooltip from '../Components/Tooltip';
import DeleteConfirmation from '../Components/DeleteConfirmation';
import Pusher from 'pusher-js';
import MentorWelcome from '../Partials/MentorWelcome';

function Plan() {
  const [role, setRole] = useAtom(roleAtom)
  const [user] = useAtom(userAtom)
  const [channel, setChannel] = useAtom(channelAtom)
  let { token } = useParams()
  const [plan, setPlan] = useState(null);
  const [plans, setPlans] = useState([]);
  const [filteredPlans, setFilteredPlans] = useState([]);
  const [sideBarOpen, setSideBarOpen] = useState(window.innerWidth >= 768);
  const [planToken, setPlanToken] = useAtom(planTokenAtom);
  const [addPlanOpen, setAddPlanOpen] = useState(false);
  const [editPlanOpen, setEditPlanOpen] = useState(false);
  const [noPlans, setNoPlans] = useState(false);
  const [joinOpen, setJoinOpen] = useState(false);
  const [accountOpen, setAccountOpen] = useState(false);
  const [inviteOpen, setInviteOpen] = useState(false);
  const [loading, setLoading] = useAtom(loadingAtom);
  const [showWelcome, setShowWelcome] = useState(false)

  const navigate = useNavigate();
  useEffect(() => {
    getPlan();

    const pusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
      cluster: import.meta.env.VITE_PUSHER_CLUSTER,
    });
    
    setChannel(pusher.subscribe(import.meta.env.VITE_PUSHER_CHANNEL));

  },[token]);



  const getPlan = () => {
    setPlanToken(token);
    setLoading(true);
    api.getPlan(token).then((response) => {
      setPlan(response.data);
      getPlans();
      if (response.data.user_id == user.id){
        setRole('youth');
      } else {
        setRole('mentors');
      }
    })
  }
  
  
  const getPlans = () => {
    api.getPlans().then((response) => {
      setPlans(response.data);
      setFilteredPlans(response.data);
      setLoading(false);
      if (response.data.length == 0 && !token){
        setAddPlanOpen(true)
        setNoPlans(true);
      }
      if (token && !response.data.find((plan) => plan.token == token)){
        setJoinOpen(true);
      }
      if (!token && response.data){
        navigate(`/plan/${response.data[0].token}`);
      }
    })
  }


  const onSearchChange = (e) => {
    const search = e.target.value.toLowerCase();
    setFilteredPlans(plans.filter((plan) => plan.name.toLowerCase().includes(search)));
  }

  const onAccount = () => {
    setAccountOpen(true);
  }

  const onPlanAdd = (plan) =>{
    setAddPlanOpen(false);
    navigate(`/plan/${plan.token}`);
  }

  const onPlanEdit = () => {
    setEditPlanOpen(false);
    getPlan();
    getPlans();
  };

  const onPlanDelete = () => {
    setEditPlanOpen(false);
    navigate('/');
  }
  
  const onLogout = () => {
    api.logout().then(()=>{
      window.location.reload();
    })
  }

  const onJoin = () => {
    api.createPlanUser({
      token
    }).then((response) => {
      if (plans.length == 0){
        setShowWelcome(true);
      }
      getPlans();
      setJoinOpen(false);
    });
  }

  const onDownload = () => {
    api.getPlanPdf(token).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', plan.name + '.pdf');
      document.body.appendChild(link);
      link.click();
    })
  }
      
  return (
    <div className="flex w-full h-screen">
      <MentorWelcome showWelcome={showWelcome} setShowWelcome={setShowWelcome}/>
      <Modal locked={true} isOpen={joinOpen} setIsOpen={setJoinOpen} width="md:max-w-xl">
        <Join onJoin={onJoin} plan={plan}/>
      </Modal>
      <div className={twJoin("w-full flex h-screen flex-col relative border-r border-r-neutral-400", sideBarOpen ? "md:max-w-[250px]" : "max-w-0")}>
          <h1 className="mt-8 px-8 mb-8 text-center text-2xl font-bold text-brand">My Mentor Circle Plans</h1>
          <div className="relative">
            <i className="fa-solid fa-search text-neutral-400 absolute top-1/2 left-4 transform -translate-y-1/2"></i>
            <input onChange={onSearchChange} type="text" placeholder="" className="w-full p-4 border border-neutral-300 px-12 text-xl focus:outline-0 focus:border-t-neutral-400"/>
          </div>
          <ul className="flex-1 overflow-scroll">
            {filteredPlans.map((plan) => (
              <li key={plan.token} className="mb-2 w-full">
                <Link className={twJoin(planToken == plan.token ? "bg-brand text-white":"bg-neutral-100 text-black", "py-4 block text-center text-lg")} to={`/plan/${plan.token}`}>{plan.name}'s Plan</Link>
              </li>
            ))}
          </ul>
        <button className="px-4 py-3 flex items-center gap-4 border-t border-t-neutral-400 leading-5 group" onClick={()=>{
          setAddPlanOpen(true)
        }}>
          <i className="fa-solid fa-plus rounded p-2 text-white bg-brand/80 group-hover:bg-brand"></i>
          <span className="text-sm uppercase font-bold text-brand/80 group-hover:text-brand">Add a Plan</span>
        </button>
        <Modal locked={noPlans} isOpen={addPlanOpen} setIsOpen={setAddPlanOpen} width="md:max-w-xl">
          <AddPlan onAdd={onPlanAdd}/>
        </Modal>
        <button className="px-4 py-3 flex items-center gap-4 border-t border-t-neutral-200 leading-5 group"  onClick={onAccount}>
          <i className="fa-solid fa-gear rounded p-2 text-white bg-brand/80  group-hover:bg-brand"></i>
          <span className="text-sm uppercase font-bold text-brand/80 group-hover:text-brand">Account</span>
        </button>
        <button
              className="px-4 py-2 flex items-center gap-4 border-t border-t-neutral-200 leading-5 group text-brand text-sm uppercase font-bold"
              onClick={onLogout}
            >
              <i className="fa-solid fa-sign-out-alt p-2 rounded text-brand"></i>
              Logout
            </button>
        <Modal isOpen={accountOpen} setIsOpen={setAccountOpen} width="md:max-w-xl">
          <EditAccount onAccountEdited={()=>
            setAccountOpen(false)
          }/>
        </Modal>
        <button className="absolute top-[24px] md:top-1/2 md:-translate-y-1/2 md:left-full z-10 bg-brand rounded-r-lg pr-3 pl-2 py-3" onClick={()=>{setSideBarOpen(!sideBarOpen)}}>
          <i className={twJoin("fa-solid fa-chevron-right text-white text-lg", sideBarOpen ? "fa-rotate-180": "")}></i>
        </button>
      </div>
      <div className="flex-1 bg-neutral-200 h-full overflow-scroll relative">
      <div className="p-10">
        <div className="p-6 border rounded-md bg-white flex-col md:flex-row flex md:items-center">
          <div className="md:border-r-2 border-neutral-200 h-full md:pr-6 md:mr-6 py-2 mb-6 md:mb-0">
            <img src="/logo.png" className="w-[223px] mx-auto "/>
          </div>
          <div className="flex justify-between items-center flex-1">
            <h1 className="text-2xl font-bold flex gap-4">
              {plan && !loading ? plan.name + "'s Plan": null}
              {role == 'youth' && !loading ? <button className="block -mt-1" onClick={()=>{
                setEditPlanOpen(true)
            }}><i className="fa-solid fa-pen-to-square text-brand text-lg"></i></button> : null}
            </h1>
            
            <div className="flex gap-8">
              {user && plan && user.id == plan.user_id && !loading ? <div>
                Editing as
                <select className="border border-neutral-300 rounded-md p-2 ml-2 pr-4 w-28" value={role} onChange={(e) => setRole(e.target.value)}>
                  <option value="youth">Youth</option>
                  <option value="mentors">Mentor</option>
                </select>
              </div> : null}
              <Tooltip text="Share This Plan" position="top">
              <button className="hidden md:flex flex-col items-center gap-1.5" onClick={()=>{if (!loading){setInviteOpen(true)}}}>
              <span class="bg-brand p-2 rounded-lg w-10 h-10 flex items-center justify-center">
                <i className="fa-solid fa-share text-white text-xl"></i>
              </span>
              <span className="text-sm font-bold text-brand/80 group-hover:text-white">Share</span>
            </button>
            </Tooltip>
              <button className="hidden md:flex flex-col items-center gap-1.5" onClick={onDownload}>
              <span class="bg-brand p-2 rounded-lg w-10 h-10 flex items-center justify-center">
                <i className="fa-solid fa-arrow-down-to-line text-white text-xl"></i>
              </span>
              <span className="text-sm font-bold text-brand/80 group-hover:text-white">Download</span>
            </button>
            </div>
            
            
            <Modal isOpen={inviteOpen} setIsOpen={setInviteOpen}  width="md:max-w-xl">
              <Invite/>
            </Modal>
            <Modal locked={noPlans} isOpen={editPlanOpen} setIsOpen={setEditPlanOpen} width="md:max-w-xl">
              <EditPlan plan={plan} onEdit={onPlanEdit} onDelete={onPlanDelete}/>
            </Modal>
          </div>
          
        </div>
      <div className="grid md:grid-cols-4 md:grid-rows-7 gap-4 pt-10">
        <div>
          <Introduction/>
        </div>
        <div className="md:hidden flex gap-2 items-center justify-center my-4">
          <i className={twJoin("fa-regular text-xl", role == 'mentors'? 'fa-rocket text-neutral-400': 'fa-pen-to-square text-brand')}></i>
          <div className={twJoin("uppercase font-bold text-sm tracking-wide",role=='mentors'? 'text-neutral-400':'text-brand')}>
            {role == 'mentors' ? 'The youth will complete these sections' : 'You will complete these sections'}
          </div>
        </div>
        <div className="md:row-span-3 md:col-start-1 md:row-start-2"><Identity/></div>
        <div className="md:row-span-3 md:col-start-1 md:row-start-5"><Gifts/></div>
        <div className="md:row-span-4 md:col-start-2 md:row-start-1"><Vision/></div>
        <div className="md:row-span-3 md:col-start-2 md:row-start-5"><MentorCircle/></div>
        <div className="md:hidden flex gap-2 items-center justify-center my-4">
          <i className={twJoin("fa-regular text-xl", role == 'youth'? 'fa-rocket text-neutral-400': 'fa-pen-to-square text-brand')}></i>
          <div className={twJoin("uppercase font-bold text-sm tracking-wide",role=='youth' ? 'text-neutral-400':'text-brand')}>
            {role == 'mentors' ? 'You will complete these sections' : 'Your mentors will complete these sections'}
          </div>
        </div>
        <div className="md:row-span-5 md:col-start-3 md:row-start-1"><Encouragement/></div>
        <div className="md:row-span-5 md:col-start-4 md:row-start-1">
          <Commitments/>
        </div>
        <div className="md:col-span-2 md:row-span-2 md:col-start-3 md:row-start-6"><Blessings/></div>
    </div>
    <div className="md:hidden mt-10 rounded-lg w-full p-4 bg-white flex justify-around">
     
      <button className="flex flex-col items-center gap-1.5" onClick={()=>{if (!loading){setInviteOpen(true)}}}>
              <span class="bg-brand p-2 rounded-lg w-10 h-10 flex items-center justify-center">
                <i className="fa-solid fa-share text-white text-xl"></i>
              </span>
              <span className="text-sm font-bold text-brand/80 group-hover:text-white">Share</span>
            </button>
    </div>

    </div>
    
    
    <div className="text-center bg-neutral-50 py-4 border-t border-t-neutral-400 border-x border-x-neutral-400"><a href="/resources" className="underline text-black/50">Check Out More Encircled Resources</a></div>
    </div>
    
    <DeleteConfirmation/>
    </div>
  )
}

export default Plan
