import Modal from "../../Components/Modal";
import { twJoin } from 'tailwind-merge'
import { channelAtom, loadingAtom, planTokenAtom, roleAtom, userAtom } from '../../state';
import { useAtom } from "jotai";
import Button from "../../Components/Button";
import { useEffect, useState } from "react";
import api from "../../services/api";
import { Formik, Form } from "formik";
const arrayKeys = ['identities', 'gifts', 'visions', 'members', 'encouragements', 'commitments', 'audio_blessings', 'text_blessings'];

function Step(props) {
   const [role] = useAtom(roleAtom)
   const [channel, setChannel] = useAtom(channelAtom)

   const editable = props.step.type == role;
   const [isOpen, setIsOpen] = useState(false)
   const [isEditing, setIsEditing] = useState(false)
   const [planToken] = useAtom(planTokenAtom);
   const [user] = useAtom(userAtom)
   const [loading, setLoading] = useAtom(loadingAtom)
   const [data, setData] = useState({
    ...props.keys.reduce((acc, key) => {
        if (arrayKeys.includes(key)){
            acc[key] = []
            return acc
        } else {
            acc[key] = ''
            return acc
        }
    }, {})
   })

   const theme = props.theme || 'brand';
   
   const isComplete = props.getIsComplete(data)
   const previewData = props.getPreviewData(data)

   useEffect(() => {
        if (!isOpen && isComplete){
            setIsEditing(false)
        } else if (isOpen && !isComplete && editable){
            setIsEditing(true)
        }
    }, [isOpen])

    useEffect(() => {
        if (planToken){
            refreshData()
        }
    },[planToken])
    
    useEffect(() => {
        if (channel){
            channel.bind('plan.' + planToken, function(data) {
                if (props.keys.includes(data.step) && data.user_id != user.id){
                    refreshData()
                }
              });
        }
        
    },[channel])

    const refreshData = () => {
        api.getPlanContent(planToken, props.keys).then((response) => {  
            setData({
                ...props.keys.reduce((acc, key) => {
                    if (key == 'audio_blessings' || key == 'text_blessings' || key == 'encouragements' || key == 'commitments' || key == 'members'){
                        acc[key] = response.data.filter(item => item.key == key).flatMap(item => item)
                        return acc
                    }
                    if (arrayKeys.includes(key)){
                        acc[key] = response.data.filter(item => item.key == key).flatMap(item => item.value)
                        return acc
                    } else {
                        acc[key] = response.data.find(item => item.key == key)?.value || ''
                        return acc
                    }
                }, {})
            });
        })
    }

    const formikOnSubmit = (values, actions) => {
        onSubmit(values);
    }

    const externalSubmit = (values,actions) => {
        props.onSubmit(values, actions, onSubmit)
    }

    const onSubmit = (values, toggleEditing = true, reset = true) => {
        api.createPlanContent({
            reset,
            content: 
            Object.keys(values).map((key) => {
                if (values[key] instanceof Array) {
                    return values[key].map(item => {
                        return {
                            plan_token: planToken,
                            key: key,
                            value: item
                        }
                    })
                } else {
                    return {
                        plan_token: planToken,
                        key: key,
                        value: values[key]
                    }
                }
            }).filter((v)=>v.value!='').flat()
        }).then(() => {
            if (toggleEditing){
                setIsEditing(false)
            }
            refreshData()
        })
      }
    return (
         <div className={"h-full border p-4 flex flex-col rounded-md hover:cursor-pointer hover:shadow " + (editable ? "bg-white" : "bg-neutral-100")} onClick={()=>{
            if (!loading){
                setIsOpen(true)
            }
         }}>
            <StepGrid props={props} editable={editable} isComplete={isComplete} previewData={previewData} loading={loading}/>
            <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
                <StepOverview props={props} editable={editable} isEditing={isEditing} setIsEditing={setIsEditing} onSubmit={props.onSubmit ? externalSubmit : formikOnSubmit} previewData={previewData} theme={theme} data={data} isComplete={isComplete} image={props.image} refreshData={refreshData}/>
            </Modal>
       </div>
    )
  }

  function StepGrid({props, editable, isComplete, previewData, loading}) {
    const Content = ()=>{
        if (loading){
            return;
        }
        if (isComplete){
            return <div className="mt-4 hidden md:block">
                <props.Preview data={previewData} editable={editable}/>
            </div>
        }
        return <StepEmpty name={props.step.name} type={props.step.type} editable={editable} view="grid"/>
    }
    return <>
        <Heading title={props.step.name} type={props.step.type} editable={editable} isComplete={isComplete} loading={loading}/>
        <Content/>
    </>
  }

  function StepOverview({props, editable, isEditing, onSubmit, isComplete, previewData, data, setIsEditing, image, theme, refreshData}) {

    const getButtonBgColor = () => {
        switch (theme) {
            case "gifts":
                return "bg-gifts hover:bg-gifts/90";
            case "vision":
                return "bg-vision hover:bg-vision/90";
            case "circle":
                return "bg-circle hover:bg-circle/90";
            case "commitments":
                return "bg-commitments hover:bg-commitments/90";
            case "blessings":
                return "bg-blessings hover:bg-blessings/90";
            case "encouragement":
                return "bg-encouragement hover:bg-encouragement/90";
            default:
                return "bg-brand hover:bg-brand/90";
        }
    }
    return <>
        <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center border-b border-neutral-200 pb-6 mb-6 gap-4">
        <div className="flex items-center lg:justify-center gap-4">
            <img src={image} className="w-[72px]"/>
            <h1 className="font-bold text-3xl text-center">{props.step.name}</h1>
        </div>
            <RoleDetails type={props.step.type}/>        
        </div>
        
        {isEditing && editable ? <Formik
                    onSubmit={onSubmit}
                    initialValues={data}
                    validationSchema={props.schema}
                    validateOnChange={false}
                    validateOnBlur={false}
                >   
                    {({values}) =>
                    <Form>
                        {props.children(values, data, refreshData)}
                    </Form>
                    }
                </Formik> : <>
                    <div className="font-bold text-brand-secondary text-xl mb-4">{props.step.title}</div>
                    {
                        isComplete ? <>
                            <props.Preview data={previewData} editable={editable} title={props.step.title} theme={theme}/>
                            {editable ? <Button bgColor={getButtonBgColor(theme)} onClick={()=>setIsEditing(true)}>Edit</Button> : null}
                        </> : 
                        <StepEmpty name={props.step.name} type={props.step.type} editable={editable} view="overview"/>
                    }
             </>
            }
         </>
  }


  function RoleDetails ({type}){
   return <div className="flex items-center gap-2 px-8 py-4 rounded-md bg-neutral-50 border rounded-xl">
       <div>
           <span class="fa-stack">
               <i class="fa-solid fa-circle fa-stack-2x text-brand"></i>
               <i class={twJoin("fa-solid fa-stack-1x text-white",(type == 'youth' ? 'fa-user' : 'fa-user-group' ))}></i>
           </span>
       </div>
       <div>
           <h2 className="text-brand font-bold">{type == 'youth' ? 'Youth' : 'Mentor'} Activity</h2>
           <span className="text-neutral-400 text-xs font-bold uppercase tracking-wide">{type == 'mentors' ? "The mentors will complete this section": "The youth will complete this section"}</span>
       </div>
       </div>
 }


 const Heading = ({editable, isComplete, title, type, loading}) => {
   return <div className="flex justify-between items-center">
       <h2 className={twJoin("font-bold text-xl text-black")}>{title}</h2>
       <div>
           <span class="fa-stack">
               <i class={twJoin("fa-solid fa-circle fa-stack-2x",editable ? "text-brand" : "text-neutral-300")}></i>
               <i class={twJoin("fa-solid fa-stack-1x",(type == 'youth' ? 'fa-user' : 'fa-user-group' ), editable ? "text-white": "text-white")}></i>
               {isComplete && !loading ? <i class={twJoin("fa-circle-check fa-solid fa-stack -mt-7 -ml-[1px]", editable ? "text-stone-500": "fa-solid text-neutral-400")}></i> : null}
           </span>
       </div>
   </div>
 }

 const StepEmpty = ({editable, name, type, onClick, view}) => {
    return (
        <div className={twJoin("md:h-full w-full justify-center items-center", view =="grid" ? "hidden md:flex": "flex")}>
            {editable ? <button onClick={onClick} className="flex flex-col gap-2 items-center">
                <i className="fa-solid fa-pen-to-square text-brand text-3xl"></i>
                <span className="text-brand text-sm font-bold">{"Add Your " + name}</span>
            </button> : 
                <div className="bg-neutral-100  w-full flex justify-center py-8">
                    <div className="max-w-[200px] flex flex-col gap-2 items-center text-center">
                        <i className="fa-regular fa-rocket text-neutral-400 text-3xl"></i>
                        <span className="text-neutral-400 text-xs font-bold uppercase tracking-wide">{type == 'mentors' ? "Your mentors will complete section.": "The youth will complete section"}</span>
                    </div>
                </div>
            }
        </div>
    )
  }
  

  export default Step
  