import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import { twJoin } from 'tailwind-merge'
function Modal({isOpen, setIsOpen, children, locked = false, width = "md:max-w-5xl"}) {
  return (
    <Dialog open={isOpen} onClose={() => !locked && setIsOpen(false)} className="relative z-50">
      <DialogBackdrop className="fixed inset-0 bg-black bg-opacity-50" />
        <div className="fixed inset-0 flex w-screen items-center justify-center md:p-4">
          <DialogPanel className={twJoin("w-full border bg-white px-12 py-12 md:px-20 md:py-14 h-full max-h-full md:h-auto relative overflow-scroll rounded-lg", width)}>
            {!locked ? <button onClick={() => setIsOpen(false)} className="absolute top-4 right-4">
              <i className="fas fa-times text-3xl text-black"></i>
            </button> : null}
            {children}
          </DialogPanel>
        </div>
      </Dialog>
  )
}

export default Modal