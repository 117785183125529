const GoalBlock = ({data}) =>{
    return <div className="mb-8" >
    <h1 class="text-vision font-bold mb-4 text-xl">Youth's 1-Year Goals</h1>
    <div>
    {data.visions.map((item, key) => {
        const tag = item !== 'other' ? item : data.other_title
        const value = data[item + '_vision']
        return <div className="flex gap-4 mb-4">
            <div className="bg-vision text-white rounded-full w-8 h-8 font-bold flex items-center justify-center shrink-0">{key+1}</div>
            <div>{value} <span className="ml-2 text-center rounded-full px-2 py-1 border grow-0 border-vision border text-vision capitalize text-xs">{item != 'other' ? item : data.other_title}</span>                                </div>
        </div>
    })}
    </div>
</div>
}

export default GoalBlock