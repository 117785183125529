import { twJoin } from 'tailwind-merge'

import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'

function Accordion({title, subtitle = null, children, classes = "px-6 py-4", theme = "default"}) {
  return (
    <Disclosure as="div" className={twJoin(classes, "border border-neutral-200 hover:border-neutral-300 rounded-md", theme == "default" ? "bg-gray-50 " : "")} defaultOpen={false}>
            {({ open }) => (
        <>
                <DisclosureButton className="group flex w-full items-center justify-between">
                    
                    <div className="flex justify-between w-full">
                      <div className={twJoin("font-bold text-sm text-left", theme == "default" ? "text-brand":"")}>
                        {title}
                     </div>
                     {subtitle ? <span className="text-xs text-black/50 pr-2">{subtitle}</span> : null}
                    </div>
                    <i class={twJoin(
                      "text-2xl fa-solid", 
                      theme == "default" ? twJoin(
                        open ? "fa-square-chevron-up" : "fa-square-chevron-down",
                        "text-brand"
                      ) : twJoin(
                        open ? "fa-chevron-up" : "fa-chevron-down",
                        "text-black/50 text-sm"
                      ))
                      }></i>
                </DisclosureButton>
                <DisclosurePanel className="mt-2 text-sm/5 text-black/50">
                    {children}
                </DisclosurePanel>
                </>
      )}
            </Disclosure>
  )
}

export default Accordion