import { Field, Form, Formik } from "formik"
import { Link, NavLink } from "react-router-dom"
import * as Yup from "yup"
import api from "../services/api"
import { useState } from "react"
import FormikField from "../Components/Forms/FormikField"
import Button from "../Components/Button"

function ForgotPassword () {
    const [isSent, setIsSent] = useState(false);

    const handleSubmit = (values, {setErrors}) => {
        api.forgotPassword(values).then((response)=>{
          setIsSent(true);
        });
    }
    
    const ForgotPasswordSchema = Yup.object().shape({
      email: Yup.string().email("Invalid email").required("Required"),
    })

    return <div className="min-h-screen flex flex-col justify-between">
        
    <div className="p-12 max-w-5xl w-full mx-auto">
        <img className="w-[300px] mb-16" src="/logo.png"/>
        <div className="flex flex-col gap-8 md:gap-16 md:flex-row">
        
        <div className="max-w-[430px]">
            <div className=" mb-12">
                <h1 className="font-bold text-xl text-black mb-8">Forgot Password</h1>
            </div>
                    {isSent ? <div>
                        <p class="text-brand-secondary text-base font-bold dark:text-white">An email has been sent to you with instructions on how to reset your password.</p>
                    </div> : <Formik onSubmit={handleSubmit} initialValues={{
                      email: "",
                    }} validationSchema={ForgotPasswordSchema}>
                      <Form> 
                          <div className="mb-7">
                              <FormikField title="Email" name="email" instructions={["Enter the email address associated with your account and we'll send you an email with a link to reset your password."]} >
                                <Field name="email" className="w-full p-4 border-0 rounded-md mb-4 bg-neutral-100"/>
                              </FormikField>
                            </div>
                            <Button type="submit">Submit</Button>
                      </Form>
                  </Formik>}
                  <p class="text-brand-secondary text-base font-bold mt-10"><Link to="/login" className="underline text-brand dark:text-white">Back to Login</Link></p>

              </div>
          </div></div></div>
}

export default ForgotPassword