import Step from "./Step";
import Button from "../../Components/Button";
import { Field } from 'formik'
import * as Yup from "yup"
import FormikField from '../../Components/Forms/FormikField'
import ListTextPreview from "../../Components/ListTextPreview";
import Accordion from "../../Components/Accordion";
import GoalBlock from "../../Components/GoalBlock";
import { useAtom } from "jotai";
import { roleAtom, userAtom } from "../../state";

const shape = {
    'commitment': Yup.string().required('Required')
}
const schema = Yup.object().shape(shape);

const step = {
    name: "Commitments",
    title: "Write 1 or 2 Commitments",
    type: "mentors",
    instructions: [
        "Encourage your youth in the Life Areas they’ve selected. Consider connecting these encouragements to your own life experiences or what you know about your youth.",
        "Write 1 or 2 Encouragements that you want to share with your youth."
    ],
    moreInfo: <div>
    <div className="mb-4">A commitment is a firm promise to provide specific support over the next year. This could take many shapes and forms it will always be connected helping youth complete their 1-Year Life Area Goals.</div>
    <div><div>Helpful Links:</div>
    <ul>
        <li><a target="_blank" className="underline text-black/50" href="https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Personal%20Journey%20-%20Examples%20of%20Commitments.pdf">Examples of Commitments</a></li>
    </ul>
</div>
</div>
  };


function Commitments() {
    const [user] = useAtom(userAtom)
    const [role] = useAtom(roleAtom);
    const getPreviewData = (data) => {return data.commitments}
    const getIsComplete = (data) => {
        if (role =='youth'){
            return data.commitments.length > 0;
        }
        return data.commitments.filter((item) => item.user_id == user.id).length > 0
    }
    const onSubmit = (values, actions, onSubmit) => {
        onSubmit(
            {commitments:[values.commitment]},
            false,
            false
        )
        actions.resetForm();
    }
    return <Step 
                step={step}
                Preview={ListTextPreview} 
                getIsComplete={getIsComplete}
                getPreviewData={getPreviewData}
                previewType="single"
                keys={['commitments', ,'visions', 'other_title', 'faith_vision', 'family_vision', 'sports_vision', 'school_vision', 'arts_music_vision', 'travel_vision', 'hobbies_vision', 'service_vision', 'other_vision', 'relationships_vision', 'work_vision']}
                schema={schema}
                image="/commitments.svg"
                theme="commitments"
                onSubmit={onSubmit}
            >
                {(values, data)=>(
                    <>
                    <FormikField primary={true} name="commitment" instructions={step.instructions} title={step.title}>

                    <GoalBlock data={data}/>

                        <div className="mb-6">
                            <Accordion title="More Info" theme="minimalist">
                                {step.moreInfo}
                            </Accordion>
                        </div>
                        <div className="mb-4 text-sm">Share 1 or 2 Commitments below</div>
                    <ListTextPreview data={getPreviewData(data)} editable={true} theme="commitments"/>

                        <div class="flex gap-4 items-center mb-2">
                            <Field as="textarea" key={data.commitments.length + 1} name="commitment" placeholder="I commit to..." className="p-2 border rounded-md w-full h-32"/>
                        </div>
                    </FormikField>  
                    <Button bgColor="bg-commitments hover:bg-commitments/90 mb-8" type="submit">Add Commitment</Button>

                    </>
                )}
    </Step>
}

export default Commitments;