import { Field, Form, Formik } from "formik"
import { Link, useParams, useSearchParams } from "react-router-dom"
import * as Yup from "yup"
import api from "../services/api"
import { useState } from "react"
import FormikField from "../Components/Forms/FormikField"
import Button from "../Components/Button"

function ResetPassword () {
    const [isReset, setIsReset] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleSubmit = (values, {}) => {
        api.resetPassword(values).then((response)=>{
            setIsReset(true);
        }).catch((error)=>{
            setIsError(true);
          });
    }

    const params = useParams();
    const [searchParams] = useSearchParams();


    const ForgotPasswordSchema = Yup.object().shape({
        email: Yup.string().email("Invalid email").required("Required"),
        password: Yup.string().required("Required").min(8, "Password must be at least 8 characters"),
        password_confirmation: Yup.string().required("Required").oneOf([Yup.ref("password"), null], "Passwords must match"),    
        token: Yup.string().required("Required"),  
    })

    return <div className="min-h-screen flex flex-col justify-between">
        
    <div className="p-12 max-w-5xl w-full mx-auto">
        <img className="w-[300px] mb-16" src="/logo.png"/>
        <div className="flex flex-col gap-8 md:gap-16 md:flex-row">
        
        <div className="max-w-[430px]">
            <div className=" mb-12">
                <h1 className="font-bold text-xl text-black mb-8">Reset Password</h1>
            </div>
                  {isError ? <div class="mb-4">
                        <p class="text-base">There was an problem resetting your password.</p><p>Please make a <Link className="underline" to="/forgot-password">new forgot password request.</Link></p></div>: null}
                  {isReset ? <div>
                        <p class="text-brand-secondary text-base font-bold dark:text-white">Your password has been reset. Please <Link to="/login">login</Link>.</p>
                    </div> :
                    <Formik onSubmit={handleSubmit} initialValues={{
                        password: "",
                        password_confirmation: "",
                        token: params.token,
                        email: searchParams.get("email")
                    }} validationSchema={ForgotPasswordSchema}>
                      <Form> 
                      <div className="mb-7">
                            <FormikField title="Password" name="password" instructions={[]}>
                              <Field type="password" name="password" className="w-full p-4 border-0 rounded-md mb-4 bg-neutral-100"/>
                            </FormikField>
                        </div>
                        <div className="mb-7">
                            <FormikField title="Confirm Password" name="password_confirmation" instructions={[]}>
                                <Field type="password" name="password_confirmation" className="w-full p-4 border-0 rounded-md mb-4 bg-neutral-100"/>
                            </FormikField>
                        </div>
                          <Button type="submit">Submit</Button>
                      </Form>
                  </Formik>}
              </div>
          </div>
      </div>
      </div>
}

export default ResetPassword;