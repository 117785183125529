import Step from "./Step";
import Button from "../../Components/Button";
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from "yup"
import FormikField from '../../Components/Forms/FormikField'
import Accordion from "../../Components/Accordion";
import VisionPreview from "../../Components/VisionPreview";

const step = {
    name: "Vision",
    title: "",
    type: "youth",
    moreInfo:<div>
      <div className="mb-4">"For I know the plans I have for you," declares the Lord, "plans to prosper you and not to harm you, plans to give you hope and a future." Jeremiah 29:11</div>
      <div>
          <div>Helpful Links:</div>
          <ul>
              <li><a target="_blank" className="underline text-black/50" href="https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Personal%20Journey%20-%20Popular%20Life%20Areas%20for%20Young%20Adults.pdf">Popular Life Areas for Young Adults</a></li>
              <li><a target="_blank" className="underline text-black/50" href="https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Personal%20Journey%20-%20Examples%20of%201%20Year%20Goals.pdf">Examples of 1-Year Goals</a></li>
          </ul>
      </div>
  </div>,
    instructions: [],
    options: [
      {
        value: "faith",
        label: "Faith"
      },
      {
        value: "family",
        label: "Family"
      },
      {
        value: "sports",
        label: "Sports"
      },
      {
        value: "school",
        label: "School"
      },
      {
        value: "arts_music",
        label: "Arts & Music",
      },
      {
        value: "travel",
        label: "Travel"
      },
      {
        value: "hobbies",
        label: "Hobbies"
      },
      {
        value: "service", 
        label: "Service"
      },
      {
        value: "work", 
        label: "Work"
      },
      {
        value: "relationships", 
        label: "Relationships"
      },
      {
        value: "other",
        label: "Other"
      }
    ]
  }

  const shape = {
    'visions': Yup.array().of(Yup.string().required('Required')).min(2, 'Please select 2-5 Visions.').max(5, 'Please select 2-5 Visions.'),
    other_title: Yup.string().when('visions', {
        is: visions => visions.includes('other'),
        then: schema => schema.required('Add a title for your custom vision.'),
    }),
    ...step.options.reduce((acc, group) => {
        acc[group.value + '_vision'] = Yup.string().when('visions', {
            is: visions => visions.includes(group.value),
            then: schema => schema.required('A goal is required for each selected vision.'),
        })
        return acc
    }, {})
}

const schema = Yup.object().shape(shape);


function Vision() {
    const getIsComplete = (data) => !!data.visions.length;
    const getPreviewData = (data) => data.visions.map((item) => {
        return {
            tag: item !== 'other' ? item : data.other_title,
            value: data[item + '_vision']
        }
    })
    return <Step 
                id="visions"
                step={step}
                Preview={VisionPreview} 
                getIsComplete={getIsComplete}
                getPreviewData={getPreviewData}
                keys={['visions', 'other_title', 'faith_vision', 'family_vision', 'sports_vision', 'school_vision', 'arts_music_vision', 'travel_vision', 'hobbies_vision', 'service_vision', 'other_vision', 'relationships_vision', 'work_vision']}
                schema={schema}
                image="/vision.svg"
                theme="vision"
            >
                {(values)=>(
                    <>
                        <FormikField primary={true} name="visions" instructions={step.instructions} title={step.title}>
                        <h2 className="font-bold text-brand-secondary text-xl mb-2">
                          STEP 1: Select 4 or 5 Life Areas
                        </h2>
                        <div className="mb-4 text-sm">
                          The Life Areas below will be the starting point for your 1-Year Goals.
                        </div>
                        <div className="mb-4 text-sm">
                          Select 4 or 5 Life Areas that are most important to you and that you want to share with your Mentor Circle.
                        </div>
                        <h2 className="font-bold text-brand-secondary text-xl mb-2">
                        STEP 2: Write 1 Goal for Each Life Area
                        </h2>
                        <div className="mb-4 text-sm">
                        Write a goal for each Life Area that you selected that you would like to accomplish in the next year.
                        </div>
                        <div className="mb-4 text-sm">
                        Make 1-Year Goals that are important to you and that your Mentor Circle can support you in.
                        </div>
                        
                        <div className="mb-6">
                            <Accordion title="More Info" theme="minimalist">
                                {step.moreInfo}
                            </Accordion>
                        </div>
                        <div className="mb-4 text-sm">Please select 4 or 5 options below</div>

                        <div>
                                {step.options.map((option, index) => {
                                    return <><div key={index} className="mb-2.5">
                                        <label className="flex items-center gap-4">
                                            <Field type="checkbox" className="h-7 w-7 rounded border-gray-300 text-vision focus:ring-vision" name="visions" value={option.value}/>
                                            <span>{option.label}
                                                {option.description ? <span className="text-neutral-300 text-sm ml-2">{option.description}</span> : null}
                                            </span>
                                        </label>
                                    </div>
                                    {option.value === 'other' && values.visions.includes(option.value) ? <>
                                    
                                    <Field name="other_title" placeholder="Other title..." className="w-full p-2 border rounded-md mb-4"/>
                                    <ErrorMessage name="other_title" render={msg => <div className="text-sm mb-4 bg-red-50 w-full p-4 rounded-lg">{msg}</div>}/>
                                    </> : null}
                                    
                                    {values.visions.includes(option.value) ? <Field as="textarea" name={option.value + '_vision'} placeholder="Set a specific, measurable goal..." className="w-full p-2 border rounded-md mb-4"/> : null}
                                    <ErrorMessage name={option.value + '_vision'} render={msg => <div className="text-sm mb-4 bg-red-50 w-full p-4 rounded-lg">{msg}</div>}/>
                                </>
                            })}
                        </div>
                        </FormikField>
                        <Button bgColor="bg-vision hover:bg-vision/90" type="submit">Save</Button>
                    </>
                )}
    </Step>
}

export default Vision;