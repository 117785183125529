
import { twJoin } from 'tailwind-merge'

function ListPreview({data, editable, theme}) {
    let bg = "bg-brand";
    if (theme == "gifts"){
        bg = "bg-gifts";
    }
    
    return <ul>
        {
            data.map((item, index) => {
                return (
                    <li key={index} className={twJoin("flex border-b border-neutral-200 gap-2",theme ? "mb-4 pb-4":"items-center mb-2 pb-2")}>
                        <span className={twJoin("w-8 h-8 text-white font-bold rounded-md flex items-center justify-center shrink-0", bg)}>{index + 1}</span>
                        <div>
                        <div className="font-bold">{item.value}</div>
                            {theme ? <p className="text-sm">{item.description}</p> : null}
                        </div>
                    </li>
                )
            }
        )}  
    </ul>
}
  
  export default ListPreview