
import { twJoin } from 'tailwind-merge'

function VisionPreview({data, editable, theme}) {
    let bg = "bg-brand";
    let tagBorder = "border-brand";
    let tagText = "text-brand";
    if (theme == "vision"){
        bg = "bg-vision";
        tagBorder = "border-vision";
        tagText = "text-vision";
    }
    
    return <ul>
        {
            data.map((item, index) => {
                return (
                    <li key={index} className="flex border-b border-neutral-200 gap-4 mb-2 pb-2 font-bold items-center">
                        <span className={twJoin("w-8 h-8 text-white font-bold rounded-md flex items-center justify-center shrink-0", bg)}>{index + 1}</span>
                        <span className={ twJoin("flex flex-1 gap-2 items-start text-black", theme ? "" : "justify-between")}><span>{item.value}</span><span className={twJoin("capitalize text-xs rounded-full px-2 py-1 border grow-0",tagBorder, tagText)}>{item.tag}</span></span>
                    </li>
                )
            }
        )}  
    </ul>
}
  
  export default VisionPreview