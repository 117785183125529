import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import { twJoin } from 'tailwind-merge'
function MentorWelcome({showWelcome, setShowWelcome}) {
  return (
    <Dialog open={showWelcome} onClose={() =>{setShowWelcome(false)}} className="relative z-50">
      <DialogBackdrop className="fixed inset-0 bg-black bg-opacity-50" />
        <div className="fixed inset-0 flex w-screen items-center justify-center md:p-4">
          <DialogPanel className={twJoin("w-full bg-brand px-16 py-12 h-auto relative overflow-scroll rounded-lg text-white max-w-[430px] text-center")}>
            <button onClick={() => setShowWelcome(false)} className="absolute top-4 right-4">
              <i className="fas fa-times text-3xl text-white"></i>
            </button>
            <h2 className="text-2xl font-bold mb-4">Welcome!</h2>
            <p className="mb-4">You've been invited to join an <span class="italic font-bold">Encircled&nbsp;Mentor&nbsp;Circle!</span></p>
            <p className="mb-4">You'll use this app to build a <span class="italic font-bold">Mentor&nbsp;Circle&nbsp;Plan</span> at your youth's upcoming <span class="italic font-bold">Mentor&nbsp;Circle&nbsp;Gathering.</span></p>
            <p>In the meantime, take a look around for resources that will help you prepare!</p>
          </DialogPanel>
        </div>
      </Dialog>
  )
}

export default MentorWelcome