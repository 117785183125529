import { Link } from "react-router-dom"
import Button from "../Components/Button"

function Resources({}) {

    const resourceGroups = [
        {
            title: "Encircled Parent Essentials",
            resources: [
                {
                    title: "0-5 Year Old",
                    description: <div>It’s never too early to invest in your child’s spiritual growth and build a network of care and support around them. As your little one grows, their awareness of the world around them is expanding rapidly. They’re taking in their environment with all of their senses and learning the foundations of what it means to be human. <b>Imagination, movement, communication</b> and <b>play</b> are especially important in this stage. What an opportunity to open their hearts, minds and senses to the realities of God all around them! </div>,
                    url: "https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Parent%20Essentials%20-%200-5%20Year%20Olds.pdf"
                },
                {
                    title: "K-5th Grade",
                    description: <div>It’s never too early to invest in your child’s spiritual growth and build a network of care and support around them. As your little one grows, their awareness of the world around them is expanding rapidly. They’re taking in their environment with all of their senses and learning the foundations of what it means to be human. <b>Imagination, movement, communication</b> and <b>play</b> are especially important in this stage. What an opportunity to open their hearts, minds and senses to the realities of God all around them! </div>,
                    url: "https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Parent%20Essentials%20-%201%20Pager%20-%20K-5th.pdf"
                },
                {
                    title: "Middle School",
                    description: <div>It’s never too early to invest in your kid’s spiritual growth and help build a network of care and support around them. Middle schoolers are in a crucial developmental stage characterized by <b>significant physical, cognitive, and emotional changes</b>. As they begin to develop a stronger sense of self, exploring interests and values it is crucial to help them ground their lives in the truths of God.</div>,
                    url: "https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Parent%20Essentials%20-%20Middle%20School.pdf"
                },
                {
                    title: "High School",
                    description: <div>It's never too late to invest in your teen's spiritual journey and cultivate a network of care and support around them. Teenagers enter a <b>transformative phase</b> as they <b>develop a clearer sense of self, explore their passions, values and interests</b> while wrestling with intensified self-awareness. This stage becomes a pivotal juncture for honing social skills, cultivating a self-identity anchored in their faith, and laying the groundwork for their aspirations and life's purpose.</div>,
                    url: "https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Parent%20Essentials%20-%20High%20School.pdf"
                },
                {
                    title: "Young Adult",
                    description: <div>Investing in your young adult's spiritual journey is a unique opportunity that transcends age boundaries. Though your relationship is different now, you as their parent continue to play a significant role in <b>nurturing their faith and honoring their unique contribution</b> in God’s Kingdom.</div>,
                    url: "https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Parent%20Essentials%20-%20Young%20Adults.pdf"
                }
            ]
        },
        {
            title: "Encircled Grandparent Essentials",
            resources: [
                {
                    title: "0-5 Year Old",
                    description: <div>It’s never too early to invest in your grandchild’s spiritual growth. Their awareness of the world around them is expanding rapidly. They’re taking in their environment with all of their senses and learning the foundations of what it means to be human. <b>Imagination, movement, communication</b> and <b>play</b> are especially important at this stage. What an opportunity to open their hearts and minds to the realities of God all around them!</div>,
                    url: "https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Grandparent%20Essentials%20-%200-5%20Year%20Old.pdf"
                },
                {
                    title: "K-5th Grade",
                    description: <div>Research shows that when kids have 5+ intentional adults in their lives, they’re more likely to grow up with a resilient faith to carry them through the different stages. As grandparents, you have an important role to play! Your grandchild is growing and developing, increasing in independence with each year and clarifying their <b>interests, passions</b> and <b>strengths</b>. As they go through the inevitable struggles of life, your love, reassurance and wisdom will help lay a foundation for their spiritual growth.</div>,
                    url: "https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Grandparent%20Essentials%20-%20K%20-%205th%20Grade.pdf",
                },
                {
                    title: "Middle School",
                    description: <div>Middle schoolers are in a crucial developmental stage characterized by significant physical, cognitive, and emotional changes. As they begin to develop a stronger sense of self, <b>exploring interests and values</b> while also <b>dealing with heightened self-consciousness</b>, you as their Grandparent, have the opportunity to remind them of the truths of God and help them lay a foundation for God’s plan for their lives.</div>,
                    url: "https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Grandparent%20Essentials%20-%20Middle%20School.pdf"
                },
                {
                    title: "High School",
                    description: <div>It's never too late to invest in your grandchild's spiritual journey. During these transformative years, teenagers experience substantial physical, cognitive, and emotional shifts. Amid these fluctuations, they construct a more defined sense of self, <b>exploring their passions, values, and interests</b> while <b>wrestling with heightened self-awareness</b>. This stage becomes a pivotal juncture for honing social skills, cultivating a self-identity anchored in their faith, and laying the foundation for their aspirations and life's purpose.</div>,
                    url: "https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Grandparent%20Essentials%20-%20High%20School.pdf"
                }
            ]
        },
        {
            title: "Encircled® Mentor Essentials",
            resources: [
                {
                    title: "0-5 Year Old",
                    description: <div>Research shows that when kids have 5+ intentional adults in their lives, they’re more likely to grow into adults with a resilient faith to carry them through the ups and downs of life. Within the first few months and years of a child’s development,  their awareness of the world around them is expanding rapidly. They’re taking in their environment with all of their senses and learning the foundations of what it means to be human. <b>Imagination, movement, communication</b> and <b>play</b> are especially important at this stage. What an opportunity to open their hearts and minds and senses to the realities of God all around them!</div>,
                    url: "https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Mentor%20Essentials%20-%200-5%20Year%20Olds.pdf"
                },
                {
                    title: "K-5th Grade",
                    description: <div>Research shows that when kids have 5+ intentional adults in their lives, they’re more likely to grow into adults with a resilient faith to carry them through the ups and downs of life. The social-emotional growth timeline is significant in elementary school as kids start to form more substantive bonds with their friends and solidify more of their sense of identity according to social norms. As a kid goes through the inevitable struggles of life, your reassurance and wisdom as a mentor can help lay a foundation that will uphold them into middle school and beyond.</div>,
                    url: "https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Mentor%20Essentials%20-%20K-5th%20Grade.pdf"
                },
                {
                    title: "Middle School",
                    description: <div>Nurturing your mentee's spiritual growth is a fulfilling endeavor that transcends age boundaries. Middle schoolers are in a pivotal developmental phase characterized by physical, cognitive, and emotional changes.They begin to develop a <b>stronger sense of self, exploring interests and values while also dealing with heightened self-consciousness</b>. This stage is crucial for building social skills, forming a self-identity grounded in the truths of God, and establishing a foundation for their future aspirations.</div>,
                    url: "https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Mentor%20Essentials%20-%20Middle%20School.pdf"
                },
                {
                    title: "High School",
                    description: <div>As a mentor, you offer steadfast guidance and help lay an unwavering foundation for the ongoing spiritual growth of a high schooler. During these <b>transformative years</b>, teenagers experience <b>substantial physical, cognitive, and emotional shifts</b>. With these fluctuations, they construct a more defined sense of self, exploring their passions, values, and interests while wrestling with heightened self-awareness. This stage becomes a pivotal juncture for honing social skills, cultivating a self-identity anchored in their faith, and laying the foundation for their aspirations and life purpose.</div>,
                    url: "https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Mentor%20Essentials%20-%20High%20School.pdf"
                }
            ]
        }
    ]
  return (
    <div className="w-full bg-neutral-50">
         <div className="text-center bg-brand/10 p-4">
         <Link to="/" className="text-black">
         <span class="underline">Create a Mentor Circle for a Youth</span> <i class="fas fa-arrow-right"></i>
         </Link>
         </div>
         <div>
        <div class="max-w-6xl mx-auto  p-10">
        <img src="/resources-header.png" class="w-full mb-8" />
        {resourceGroups.map((group) => (
            <div>
                <h2 className="text-2xl font-bold">{group.title}</h2>
                <div class="grid md:grid-cols-2 mb-4">
                    {group.resources.map((resource) => (
                        <div class="rounded-lg bg-white p-12 m-4 border border-neutral-200">
                            <h3 class="text-xl font-bold mb-4">{resource.title}</h3>
                            <p class="mb-4">{resource.description}</p>
                            <a href={resource.url} target="_blank"><Button>Download</Button></a>
                        </div>
                    ))}
                </div>
            </div>
        ))}
        </div>
        </div>
    </div>
  )
}

export default Resources