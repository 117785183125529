import { twJoin } from "tailwind-merge"
import { useAudioRecorder } from 'react-audio-voice-recorder';
import { useState } from "react";
import Button from "./Button";

function Recorder({onComplete, onReset}) {
    const [isPlaying, setIsPlaying] = useState(false);
    const {
        startRecording,
        stopRecording,
        recordingBlob,
        isRecording
      } = useAudioRecorder();

      const play = () => {
        setIsPlaying(true);
        const blobUrl = URL.createObjectURL(recordingBlob);
        const audio = new Audio();
        audio.src = blobUrl;
        audio.controls = true;
        document.body.appendChild(audio);
        //Optionally, if you want to start playing right away:
        audio.play();
        audio.onended = () => {
          setIsPlaying(false);
          audio.remove();
        };

      }

   const MainButton = () => {

            let icon = "fa-microphone-alt";
            let label = "Record";
            let onClick = startRecording;

            if (isRecording){
                icon = "fa-stop";
                label = "Stop";
                onClick = stopRecording;
            }
        
            if (recordingBlob && !isPlaying){
                icon = "fa-rotate-right";
                label = "Replay";
                onClick = play;
            }
            
            
            if (isPlaying){
                icon = "fa-ellipsis"
                label = "Playing"
            }

            return <div className="mx-auto w-[300px] h-[300px] bg-blessings rounded-full flex items-center justify-center" onClick={onClick}>
                <div className="text-center cursor-pointer">
                <i className={twJoin("fas text-8xl text-white mb-3",icon)}></i>
                <div className="text-white text-2xl">{label}</div>
                </div>
            </div> 
   }

  return (
    <div>
        <MainButton/> 
    
    {recordingBlob ? 
            <div className="flex gap-4 mt-8 justify-between">
                <Button bgColor="bg-blessings hover:bg-blessings/90" onClick={()=>onReset()}>Restart</Button>
                <Button bgColor="bg-blessings hover:bg-blessings/90" onClick={()=>onComplete(recordingBlob)}>Publish</Button>
            </div>
         : 
        
        
        null}

    </div>
  )
}

export default Recorder