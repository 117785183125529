import { useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge"
import api from "../services/api";
import { deleteConfirmationAtom, userAtom } from "../state";
import { useAtom } from "jotai";

function BlessingsPreview({data, editable, theme, onDelete, deletable = false}) {
    let textColor = "text-brand";
    const [user, setUser] = useAtom(userAtom)
    const [deleteConfirmation, setDeleteConfirmation] = useAtom(deleteConfirmationAtom);

    if (theme == "blessings"){
        textColor = "text-blessings";
    }

    const onDeleteClick = (id) => {
        setDeleteConfirmation(()=>()=>{
            api.deletePlanContent(id).then((response)=>{
                onDelete();
            })
        })
    }
    return <div class="flex gap-4 flex-col md:flex-row">
    <div className="flex-1">
        <div class={twJoin("mb-4 font-bold", editable ? "text-black": "text-neutral-500")}>Audio Blessings</div>
        <ul>
            {
                data.audio_blessings.map((item, index) => {
                    return (
                        <AudioBlessing key={item.id} item={item} textColor={textColor} editable={editable} index={index} onDeleteClick={()=>onDeleteClick(item.id)} deletable={deletable} />
                    )
                }
            )}  
        </ul>
    </div>
    <div className="flex-1">
        <div class={twJoin("mb-4 font-bold", editable ? "text-black": "text-neutral-500")}>Text Blessings</div>
        <ul>
            {
                data.text_blessings.map((item, index) => {
                    return (
                        <li key={index} className={twJoin("flex text-sm gap-2 mb-2 pb-2",editable ? "text-black": "text-neutral-500")}>{item.value} - {item.user_name}
                            {item.user_id == user.id && deletable ? <i onClick={()=>onDeleteClick(item.id)} className="fa-solid fa-square-minus text-neutral-400 mt-0.5"></i> : null}
                        </li>
                    )
                }
            )}  
        </ul>
    </div>
</div>
}

const AudioBlessing = ({item, textColor, editable, index, onDeleteClick, deletable}) => {
    const [media, setMedia] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [user, setUser] = useAtom(userAtom)

    const audioRef = useRef(null);

    useEffect(() => {
        api.getMedia(item.value).then((response)=>{
            setMedia(response.data)
        })
    },[item]);

    const onClick = () => {
        if (playing) {
            setPlaying(false);
            audioRef.current.pause();
        } else {
            setPlaying(true);
            audioRef.current.play()
    
            // on audio end
            audioRef.current.onended = () => {
                setPlaying(false);
            }
        }
    }

    return (
        media ? <li key={index} className="flex gap-2 mb-2 font-bold items-center">
                <i className={twJoin("fa-solid text-3xl", textColor, playing ? "fa-circle-pause" : "fa-circle-play")} onClick={onClick}></i>
                <audio ref={audioRef} src={media.url} />
            <span className={editable ? "text-black": "text-neutral-500"}>{media.user_name}'s Blessing</span>
            {item.user_id == user.id && deletable ? <i onClick={()=>onDeleteClick(item.id)} className="fa-solid fa-square-minus text-neutral-400"></i> : null}
        </li> : null
    )
}  
  export default BlessingsPreview