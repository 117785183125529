import Step from "./Step";
import Button from "../../Components/Button";
import { Field, Form, Formik } from 'formik'
import * as Yup from "yup"
import FormikField from '../../Components/Forms/FormikField'
import ListTextPreview from "../../Components/ListTextPreview";
import Accordion from "../../Components/Accordion";
import { roleAtom, userAtom } from "../../state";
import { useAtom } from "jotai";

const shape = {
    'encouragement': Yup.string().required('Required')
}
const schema = Yup.object().shape(shape);

function Encouragement() {
    const [user] = useAtom(userAtom)
    const [role] = useAtom(roleAtom);
    const getPreviewData = (data) => {return data.encouragements}
    const getIsComplete = (data) => {
        if (role =='youth'){
            return data.encouragements.length > 0;
        }
        return data.encouragements.filter((item) => item.user_id == user.id).length > 0
    }
    const onSubmit = (values, actions, onSubmit) => {
        onSubmit(
            {encouragements: [values.encouragement]},
            false,
            false
        )
        actions.resetForm();
    }
    
    const step = {
        name: "Encouragements",
        title: "Write 1 or 2 Encouragements",
        type: "mentors",
        instructions: [
            "Encourage your youth in the Life Areas they’ve selected. Consider connecting these encouragements to your own life experiences or what you know about your youth.",
            "Write 1 or 2 Encouragements that you want to share with your youth."
        ],
        moreInfo: <div>
    <div className="mb-4">An encouragement is a positive reinforcement that reflects personal observation of growth and effort. Itis grounded in real experience and is meant to uplift and inspire. Your encouragements should align with the youth’s goals and point towards their potential.</div>
    <div>
        <div>Helpful Links:</div>
        <ul>
            <li><a target="_blank" className="underline text-black/50" href="https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Personal%20Journey%20-%20Examples%20of%20Encouragements.pdf">Examples of Encouragements</a></li>
        </ul>
    </div>
</div>
      };
    
    return <Step 
                step={step}
                Preview={ListTextPreview} 
                getIsComplete={getIsComplete}
                getPreviewData={getPreviewData}
                keys={['encouragements','visions', 'other_title']}
                schema={schema}
                onSubmit ={onSubmit}
                 image="/encouragement.svg"
                theme="encouragement"
            >
                {(values, data)=>(
                    <>
                    <FormikField primary={true} name="encouragement" instructions={step.instructions} title={step.title}>
                    
                    <div className="mb-8 max-w-md" >
                        <h1 class="text-vision font-bold mb-4 text-xl">Youth's Life Areas</h1>
                        <div className="grid grid-cols-2 gap-4">
                        {data.visions.map((item) => {
                            return <span className="text-center rounded-full px-2 py-1 border grow-0 border-vision border text-vision capitalize">{item != 'other' ? item : data.other_title}</span>
                        })}

                        </div>
                    </div>

                    <div className="mb-6">
                            <Accordion title="More Info" theme="minimalist">
                                {step.moreInfo}
                            </Accordion>
                        </div>

                        <div className="mb-4 text-sm">Share 1 or 2 Encouragements below</div>
                        <ListTextPreview data={getPreviewData(data)} editable={true} theme="encouragement"/>

                        <div class="flex gap-4 items-center mb-2 mt-4">
                            <Field as="textarea" key={data.encouragements.length + 1} name="encouragement" placeholder="You are..." className="p-2 border rounded-md w-full h-32"/>
                        </div>
                    </FormikField> 
                    
                    <Button bgColor="bg-encouragement hover:bg-encouragement/90 mb-8" type="submit">Add Encouragement</Button>
                    
                    </>
                )}
    </Step>
}

export default Encouragement;