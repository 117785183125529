
import QRCode from "react-qr-code"
import Button from "../Components/Button"
import { createRef } from "react"

function Invite() {

    const copyLink = createRef()

    const onCopy = () => {
        navigator.clipboard.writeText(window.location.href)
        copyLink.current.innerText = "Copied!"
        setTimeout(() => {
            copyLink.current.innerText = "Copy Link"
        }
        , 2000)
    }

    const onEmail = () => {
        window.location.href = `mailto:?subject=Join My Mentor Circle&body=I would like to invite you to join my mentor circle. Click the link below to join: ${window.location.href}`
    }

    return <div>
        <h1 className="font-bold text-2xl text-center mb-6">Share</h1>
        <input type="text" className="w-full h-12 px-4 text-xl mb-4 rounded" value={window.location.href} />
        <div className="mb-4">
            <Button width="w-full" onClick={onCopy}><i className="fa-solid fa-link mr-2"></i><span ref={copyLink}>Copy Link</span></Button>
        </div>

        <div className="mb-4">
            <Button width="w-full" onClick={onEmail}><i className="fa-solid fa-envelope mr-2"></i>Invite via Email</Button>
        </div>
        <div className="text-center uppercase py-6 pb-4 px-4 rounded-lg border-black border uppercase font-bold">
        
        
        <div className="flex justify-center">
            <div className="h-48 w-48">
                    <QRCode
                        size={48}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={window.location.href}
                        viewBox={`0 0 256 256`}
                    />
                </div>
            </div>      
            <div className="mt-2.5"><i className="fa-solid fa-qrcode mr-2"></i>Invite via QR Code</div>

        </div>  
        
    </div>
  }
  
  export default Invite
  