import { useState } from "react";
import Modal from "../../Components/Modal";
import { loadingAtom } from "../../state";
import { useAtom } from "jotai";

function Introduction() {
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useAtom(loadingAtom)

    return <div className={"h-full border p-4 flex flex-col rounded-md hover:cursor-pointer hover:shadow bg-white"} onClick={()=>{
        if (!loading){
            setIsOpen(true)

        }
     }}>
        <h2 className="font-bold text-lg">Introduction</h2>
        <div>
        <button className="py-3 flex items-center gap-2 leading-5 group" onClick={()=>{
          
        }}>
          <i className="fa-solid fa-circle-play text-brand rounded text-2xl"></i>
          <span className="text-base font-bold text-brand/80 group-hover:text-brand">Watch Video</span>
        </button>


        </div>
        
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="flex justify-between items-center border-b border-neutral-200 pb-6 mb-6">
            <div className="flex items-center justify-center gap-4">
                <img src="/logo-cut.png" className="w-[72px]"/>
                <h1 className="font-bold text-3xl text-center">Introduction</h1>
                
            </div>
                
            </div>
            <h2 className="mb-4 font-bold text-2xl">Welcome to the Encircled Personal Journey!</h2>

            <p className="mb-4">This experience is designed to help youth grow in faith and build a strong system of support through a Mentor Circle.</p>
            <p className="mb-4">In the first session, youth will engage in activities that explore their Identity, Gifts, Vision and Mentor Circlce.</p>
            <p className="mb-4">In the second session, the youth's Mentor Circle will speak life into them by offering Encouragements, Commitments and Blessings.</p>
            <div>
            <div className="mb-4 relative pt-[56.25%] print:hidden">
                <iframe
                src="https://player.vimeo.com/video/1034550892?title=0&byline=0"
                className="absolute left-0 top-0 h-full w-full"
                allow="autoplay; fullscreen; picture-in-picture"
                ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
            </div>

            <h2 className="mb-4 font-bold text-2xl">Youth Resources</h2>
            <p className="mb-8">Below are resources to help you cultivate a Mentor Circle:
            <ul className="mt-2">
                <li className="mb-1">👉 <a target="_blank" className="underline text-[#4D81C5]" href="http://encircled.pro.viasurvey.org">VIA Strengths Survey (HIGHLY RECOMMENDED)</a></li>
                <li className="mb-1">👉 <a target="_blank" className="underline text-[#4D81C5]" href="https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Personal%20Journey%20-%20Popular%20Life%20Areas%20for%20Young%20Adults.pdf">Popular Life Areas for Young Adults</a></li>
                <li className="mb-1">👉 <a target="_blank" className="underline text-[#4D81C5]" href="https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Personal%20Journey%20-%20Examples%20of%201%20Year%20Goals.pdf">Examples of 1-Year Goals</a></li>
            </ul>

            </p>

            <h2 className="mb-4 font-bold text-2xl">Mentor Circle Resources</h2>
            <p className="mb-8">The resources below will help you prepare for the Mentor Circle Gathering:
                <ul className="mt-2">
                    <li className="mb-1">👉 <a target="_blank" className="underline text-[#4D81C5]" href="https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Personal%20Journey%20-%20Examples%20of%20Encouragements.pdf">Examples of Encouragements</a></li>
                    <li className="mb-1">👉 <a target="_blank" className="underline text-[#4D81C5]" href="https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Personal%20Journey%20-%20How%20to%20Give%20and%20Receive%20Blessings.pdf">How to Give and Receive Blessings</a></li>
                </ul>
            </p>

            <h2 className="mb-4 font-bold text-2xl">Guide Resources</h2>
            <p className="mb-8">The resources below will help you lead your youth through the Encircled Personal Journey
                <ul className="mt-2">
                    <li className="mb-1">👉 <a target="_blank" className="underline text-[#4D81C5]" href="https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Personal%20Journey%20-%20Guide%20Handbook.pdf">Encircled Guide Handbook (HIGHLY RECOMMENDED)</a></li>
                    <li className="mb-1">👉 <a target="_blank" className="underline text-[#4D81C5]" href="https://encircled-app.nyc3.cdn.digitaloceanspaces.com/resources/Encircled%20Personal%20Journey%20-%20Facilitator%20Slides.pdf">Personal Journey Facilitation Slides</a></li>
                </ul>

            </p>
            
        </Modal>
    </div>
}

export default Introduction;