import { twJoin } from "tailwind-merge"

function Label({ name, children, primary = false }) {
    return (
      <label className={twJoin("font-bold text-brand-secondary",primary ? "text-xl":"")} htmlFor={name}>
        {children}
      </label>
    )
  }
  
  export default Label
  